/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../Components/Page";
import SearchBox from "../../Components/SearchBox";
import styled from "styled-components";
import TotalBox from "../../Components/TotalBox";
import LiveDemoCategory from "../../Components/LiveDemoCategory";
import LiveDemoCard from "../../Components/LiveDemoCard";

const Container = styled.div`
    margin-top: 15px;
    display: flex;
    gap: 40px;
`;

const CategoryList = styled.div`
    padding-left: 20px;
    width: 270px;
    min-width: 270px;
`;

const CategoryListTop = styled.h2`
    font-size: 21px;
    color: #343c50;
    font-weight: 600;
    margin-bottom: 10px;
`;

const Category = styled.div`
  width: 100%;
  padding: 7px 0;

  &:hover {
    h2 {
      color: #769af2;
    }
  }
  cursor: pointer;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: ${props=>props.current ? "#507cf2" : "#7e838f"};
  }
`;

const CardBoxContainer = styled.div`
    background-color: #e6ecfb;
    width: 100%;
    padding: 30px;
    padding-right: 11px;
`;

const CardBox = styled.div`
    height: 900px;
    width: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-auto-rows: 260px;
    gap: 40px;
    padding-right: 11px;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bcbec4;
        border-radius: 10px;
        
        &:hover {
            background-color: #abadb3;
        }
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    & > a {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export default ({filteredData, categoryList, handleSearch, keyword, category, setCategory}) => {
    return (
        <Page title="Live Demo">
            <div className="inner">
                <SearchBox handleSearch={handleSearch} initKeyword={keyword} />
                <TotalBox total={filteredData ? filteredData.length : 0} isFilltered={keyword && keyword !== ""} />
                <Container>
                    <CategoryList>
                        <CategoryListTop>Menu</CategoryListTop>
                        <Category current={category === undefined} onClick={() => setCategory(undefined)}><h2>All Contents</h2></Category>
                        {categoryList && (
                            Object.keys(categoryList).map((item, i) => {
                                return (
                                    <LiveDemoCategory
                                        key={`category${i}`}
                                        category={category} 
                                        setCategory={setCategory} 
                                        categoryInfo={{
                                            category: item,
                                            subCategoryList: categoryList[item]
                                        }}
                                    />
                                )
                            })
                        )}
                    </CategoryList>
                    <CardBoxContainer>
                        <CardBox>
                            {filteredData && filteredData.map((item) => (
                                <LiveDemoCard data={item} key={`card${item._id}`} category={category} keyword={keyword} />
                            ))}
                        </CardBox>
                    </CardBoxContainer>
                </Container>
            </div>
        </Page>
    )
}
