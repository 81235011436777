import styled from "styled-components";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import { useState } from "react";

const Container = styled.div``;

const Category = styled.div`
  width: 100%;
  padding: 7px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    h2 {
      color: #769af2;
    }
  }
  cursor: pointer;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: ${props=>props.current ? "#507cf2" : "#7e838f"};
  }

  svg {
    font-size: 20px;
    color: #7e838f;
  }
`;

const SubCategoryList = styled.div`
  border-left: 1px solid #bcbec4;
  padding-left: 8px;
  width: 100%;
`;

const SubCategory = styled.div`
  padding: 6px 12px;
  border-radius: 10px;

  color: ${props=>props.current ? "#ffffff" : "#7e838f"};
  background-color: ${props=>props.current ? "#507cf2" : "initial"};

  &:hover {
    color: ${props=>props.current ? "#ffffff" : "#769af2"};
    background-color: ${props=>props.current ? "#507cf2" : "initial"};
  }

  cursor: pointer;
`;

export default function LiveDemoCategory({category, setCategory, categoryInfo}) {
  const [show, setShow] = useState(category?.category === categoryInfo.category);
  
  const handleSubCategory = (subCategory) => {
    setCategory({
      category: categoryInfo.category,
      subCategory
    });
  }

  return (
    <Container>
      <Category onClick={() => setShow(prev => !prev)} current={category?.category === categoryInfo.category}>
        <h2>{categoryInfo.category}</h2>
        {show ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </Category>
      {show && (
        <SubCategoryList>
          {categoryInfo.subCategoryList.map((subCategory) => (
            <SubCategory 
              key={`${categoryInfo.category}_${subCategory}`} 
              onClick={() => handleSubCategory(subCategory)}
              current={category?.category === categoryInfo.category && subCategory === category?.subCategory}
            >{subCategory}</SubCategory>
          ))}
        </SubCategoryList>
      )}
    </Container>
  )
}