import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { isoftlab } from "../../api";
import { paramsParsing } from "../../utils";
import Presenter from "./livedemo.presenter";

export default withRouter((urlProps) => {
  const query = urlProps?.location?.search?.substr(1);
  const params = paramsParsing(query, {
    category: null,
    subCategory: null
  });
  const initCategory = params.category ? {
    category: params.category,
    subCategory: params.subCategory
  } : undefined;

  const [data, setData] = useState();
  const [categoryList, setCategoryList] = useState();
  const [category, setCategory] = useState(initCategory);
  const [keyword, setKeyword] = useState("");
  const [filteredData, setFilteredData] = useState();

  const handleSearch = (event) => {
    const {target: {value}} = event;

    filteringData(category, value);
    setKeyword(value);
  }

  const handleCategory = (newCategory) => {
    const convertAnd = (sentence) => {
      return encodeURIComponent(sentence);
    }
    const {
      history: {
        location: { pathname },
      },
    } = urlProps;

    let newUrl = pathname;

    if (newCategory) {
      newUrl += `?category=${convertAnd(newCategory.category)}&subCategory=${convertAnd(newCategory.subCategory)}`;
    }

    urlProps.history.push(newUrl);

    filteringData(newCategory, keyword);
    setCategory(newCategory);
  }

  const filteringData = (category, keyword) => {
    const newFilteredData = data.filter(item => {
      const isCategory = category === undefined || (category?.category === item.Category[0] && category?.subCategory === item.Component[0]);
      const isKeyword = keyword === "" || (item.Title.toLowerCase()).includes(keyword.toLowerCase());

      return isCategory && isKeyword;
    });

    setFilteredData(newFilteredData);
  }

  useEffect(() => {
    (async () => {
      const {data: demoData} = await isoftlab.getLiveDemo();
      const demoCategory = {};

      demoData.forEach((item) => {
        const itemCategory = item.Category[0];
        const itemComponent = item.Component[0];

        if (demoCategory[itemCategory]) {
          if (!demoCategory[itemCategory].includes(itemComponent)) {
            demoCategory[itemCategory].push(itemComponent);
          }
        } else {
          demoCategory[itemCategory] = [itemComponent];
        }
      });

      const newFilteredData = demoData.filter(item => {
        const isCategory = category === undefined || (category?.category === item.Category[0] && category?.subCategory === item.Component[0]);
        const isKeyword = keyword === "" || item.Title.includes(keyword);
  
        return isCategory && isKeyword;
      });

      setData(demoData);
      setFilteredData(newFilteredData);
      setCategoryList(demoCategory);
    })();
  }, []);
  return <Presenter data={data} categoryList={categoryList} handleSearch={handleSearch} keyword={keyword} filteredData={filteredData} category={category} setCategory={handleCategory} />
});