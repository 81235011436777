import React from "react";
import styled from "styled-components";

const Item = styled.div`
    border: 2px solid #bcbec4;
    border-radius: 10px;
    padding: 30px;
    color: #343c50;
    opacity: 0.8;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: ${({ isHome }) => (isHome ? "initial" : "400px")};
    height: ${({ isHome }) => (isHome ? "initial" : "100%")};

    & > div {
        width: 100%;

        & > div {
            width: 100%;
        }

    }

    &:hover {
        opacity: 1;
        border: 2px solid #507cf2;
    }

    .doi {
        word-break: break-all;
    }

    li {
        list-style-type: none;
    }
`;

const ItemCategory = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 13px;

    @media only screen and (max-width: 865px) {
        height: ${({ isHome }) => (isHome ? "30px" : "initial")};
        overflow: ${({ isHome }) => (isHome ? "hidden" : "auto")};
    }

    & > li {
        font-size: ${({ isHome }) => (isHome ? "11px" : "13px")};
        background-color: ${({ reverse }) => (reverse ? "#ffffff" : "#507cf2")};
        color: ${({ reverse }) => (reverse ? "#507cf2" : "#ffffff")};
        border: 1px solid ${({ reverse }) => (reverse ? "#507cf2" : "#ffffff")};
        padding: 5px 15px;
        border-radius: 100px;
        white-space: nowrap;
    }
`;

const ItemTitle = styled.h3`
    font-size: 18px;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: ${({ isHome }) => (isHome ? "15px" : "0")};
    min-height: ${({ isHome }) => (isHome ? "initial" : "117px")};
    line-height: 1.4;

    ${({ isHome }) =>
        isHome && {
            overflow: "hidden",
            "text-overflow": "ellipsis",
            "word-wrap": "break-word",
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical",
        }}
`;

const ItemContent = styled.ul`
    & > li {
        display: flex;
        /* align-items: center; */

        & > span:first-child {
            display: block;
            width: ${({ isHome }) => (isHome ? "25px" : "35px")};
            min-width: ${({ isHome }) => (isHome ? "25px" : "35px")};
            margin-top: 2px;
            color: #507cf2;
            font-size: ${({ isHome }) => (isHome ? "18px" : "20px")};
        }

        & > span:last-child {
            font-size: ${({ isHome }) => (isHome ? "13px" : "15px")};
            margin-top: ${({ isHome }) => (isHome ? "2px" : "3px")};
        }

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    /* & > li:first-child {
        & > span:first-child {
            font-size: 18px;
        }
    } */
`;

const ItemHashtag = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${({ isHome }) => (isHome ? "10px" : "21px")};

    @media only screen and (max-width: 865px) {
        max-height: ${({ isHome }) => (isHome ? "50px" : "initial")};
        overflow: ${({ isHome }) => (isHome ? "hidden" : "auto")};

        & > li:not(:last-child) {
            margin-right: 0px;
        }
    }


    & > li {
        font-size: ${({ isHome }) => (isHome ? "11px" : "14px")};

        & > span:first-child {
            margin-right: 5px;
            color: #507cf2;
        }
    }

    & > li:not(:last-child) {
        margin-right: 12px;
    }
`;

const CopyBox = styled.div`
    margin-top: 23px;
    display: flex;
    gap: 20px;
`;

const CopyBtn = styled.div`
    width: 100%;
    color: #7e838f;
    border: 1px solid #7e838f;
    border-radius: 10px;
    outline: 0;
    height: 50px;
    background-color: #fff;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    
    &:hover {
        background-color: #7e838f;
        color: #ffffff;
    }
`;

const clickBibtex = (event, text) => {
    event.preventDefault();
    const clipBoard = document.querySelector(".clipBoard");
    const successBox = document.querySelector(".successBox");

    clipBoard.classList.remove("hide");
    clipBoard.value = text;
    clipBoard.select();

    document.execCommand("copy");

    clipBoard.blur();

    successBox.style.transition = "none";
    successBox.style.opacity = "1";
    successBox.style.zIndex = "9999";
    setTimeout(() => {
        successBox.style.transition =
            "opacity 0s linear 1.5s, z-index 0s linear 1.5s";
        successBox.style.opacity = "0";
        successBox.style.zIndex = "-9999";
    }, 10);
};

const clickReference = (event, { author, title, name, date, bibtex }) => {
    event.preventDefault();
    let text = `${author.join(", ")}, ${title}, ${name}`;
    const bibtexInfo = {
        volume: null,
        number: null,
        pages: null,
    };

    bibtex = bibtex.split(",");

    const keyMap = ["volume", "pages", "number"];
    bibtex.forEach((item) => {
        item = item.trim();
        item = item.split("=");

        if (item.length === 2 && keyMap.includes(item[0])) {
            const value = item[1].replace("{", "").replace("}", "");

            if (value !== "") {
                bibtexInfo[item[0]] = value;
            }
        }
    });

    if (bibtexInfo.volume) {
        text += `, Volume ${bibtexInfo.volume}`;
    }
    if (bibtexInfo.number) {
        text += `, Number ${bibtexInfo.number}`;
    }
    if (bibtexInfo.pages) {
        text += `, Pages ${bibtexInfo.pages}`;
    }

    const monthMap = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        10: "October",
        11: "November",
        12: "December",
    };

    date = date.split("-");

    text += `, ${monthMap[date[1]]}, ${date[0]}`;

    const clipBoard = document.querySelector(".clipBoard");
    const successBox = document.querySelector(".successBox");

    clipBoard.classList.remove("hide");
    clipBoard.value = text;
    clipBoard.select();

    document.execCommand("copy");

    clipBoard.blur();

    successBox.style.transition = "none";
    successBox.style.opacity = "1";
    successBox.style.zIndex = "9999";
    setTimeout(() => {
        successBox.style.transition =
            "opacity 0s linear 1.5s, z-index 0s linear 1.5s";
        successBox.style.opacity = "0";
        successBox.style.zIndex = "-9999";
    }, 10);
};

export default ({ clone, item, isHome}) => (
    <a
        className={clone ? clone : ""}
        key={item._id}
        href={item["Url"]}
        target="_blank"
    >
        <Item isHome={isHome}>
            <div>
                <ItemCategory
                    isHome={isHome}
                    reverse={item.Tags.includes("Conference")}
                >
                    <li>{item.Tags}</li>
                    <li>{item.ShortName}</li>
                </ItemCategory>
                <ItemTitle isHome={isHome}>{item.Title}</ItemTitle>
                <ItemContent isHome={isHome}>
                    {item.Author && !isHome && (
                        <li>
                            <span className="material-icons-outlined">
                                people_alt
                            </span>
                            {item.Author.join(", ")}
                        </li>
                    )}
                    {item.Date && (
                        <li>
                            <span className="material-icons-outlined">
                                calendar_today
                            </span>
                            <span>{item.Date}</span>
                        </li>
                    )}

                    {item.Name && !isHome && (
                        <li>
                            <span className="material-icons-outlined">
                                history_edu
                            </span>
                            <span>{item.Name}</span>
                        </li>
                    )}

                    {item.ImpactFactor && !isHome && (
                        <li>
                            <span className="material-icons-outlined">
                                task_alt
                            </span>
                            <span>{`Impact Factor: ${item.ImpactFactor}`}</span>
                        </li>
                    )}

                    {item.DOI && !isHome && (
                        <li>
                            <span className="material-icons-outlined">
                                link
                            </span>
                            <span className="doi">{`DOI: ${item.DOI.replace(
                                "https://",
                                ""
                            )}`}</span>
                        </li>
                    )}
                </ItemContent>
            </div>
            <div>
                <ItemHashtag isHome={isHome}>
                    {item.Keyword &&
                        item.Keyword.map((keyword, i) => (
                            <li key={i}>
                                <span>#</span>
                                <span>{keyword}</span>
                            </li>
                        ))}
                </ItemHashtag>
                {!isHome && (
                    <CopyBox>
                        <CopyBtn
                            onClick={(event) => clickBibtex(event, item.Bibtex)}
                        >
                            Copy BibTeX
                        </CopyBtn>
                        <CopyBtn
                            onClick={(event) =>
                                clickReference(event, {
                                    author: item.Author,
                                    title: item.Title,
                                    name: item.Name,
                                    date: item.Date,
                                    bibtex: item.Bibtex,
                                })
                            }
                        >
                            Copy reference
                        </CopyBtn>
                    </CopyBox>
                )}
            </div>
        </Item>
    </a>
);
