import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    
    * {
        box-sizing: border-box;
    }

    body {
        overflow-x: hidden;
        overflow-y: auto;
        word-break: keep-all;
        word-wrap: break-word;
        position: relative;
    }

    body, button, input, select, td, textarea, th, a {
        font-family: 'Poppins', 'Noto Sans KR', sans-serif;
        font-size: 15px;
        color: #343c50;
        line-height: 1.5;
    }

    a {
        text-decoration: none;
        font-size: inherit;
        font-family: inherit;
        color: inherit;
    }

    pre {
        font-family: monospace;
    }

    code {
        font-family: monospace;
    }

    .inner,
    .shortInner {
        width: 100%;
        margin: 0 auto;
        max-width: 1280px;
        padding: 0 20px;
    }

    .shortInner {
        max-width: 1000px;
    }

    .hide {
        display: none !important;
    }

    .show {
        display: block;
    }
`;
