import React, { useState, useEffect, useRef } from "react";
import Presenter from "./home.presenter";
import { isoftlab } from "../../api";

const ANIMATION_GAP = 5;

export default () => {
    const useHome = () => {
        const [datas, setDatas] = useState({});

        const init = async () => {
            const { data: introData } = await isoftlab.getIntroduction();
            const { data: projectsData } = await isoftlab.getProject();
            const { data: publicationsData } = await isoftlab.getPublications();
            const { data: seminarsData } = await isoftlab.getSeminar();
            const { data: newsData } = await isoftlab.getNews();

            const processingNews = [...newsData.fixed, ...newsData.unfixed]
                .filter(data => data.Main)
                .sort((a, b) => {
                    if (a.Date > b.Date) {
                        return -1;
                    } else if (a.Date < b.Date) {
                        return 1;
                    }
                    return 0;
                });
            
            setDatas({
                intros: introData,
                news: processingNews,
                projects: projectsData.slice(0, 3),
                publications: publicationsData.filter(data => data.Selected).slice(0, 3),
                seminars: seminarsData.slice(0, 3)
            });
        };

        useEffect(() => {
            init();
        }, []);

        return {
            datas,
        };
    };

    return <Presenter {...useHome()} />;
};
