import React, { useEffect, useState } from "react";
import Presenter from "./projects-detail.presenter";
import { withRouter } from "react-router-dom";
import { isoftlab } from "../../../../api";

export default withRouter(
    ({
        history,
        history: {goBack},
        location: { state }
    }) => {
        const useProject = () => {
            const [project, setProject] = useState(null);

            const init = async () => {
                if (!state || !state.data) {
                    let id = history.location.pathname.split("/").pop();
                    const {data} = await isoftlab.getProject();
                    const detailData = data.filter(d => d["_id"] === id);

                    if (detailData.length > 0) {
                        setProject(detailData[0]);
                    } else {
                        history.push("/error");
                    }
                } else {
                    setProject(state.data);
                    window.scrollTo(0, 0);
                }
            }
            useEffect(() => {
                init();
            }, []);

            return { project };
        };

        const { project } = useProject();
        if (project) {
            return <Presenter project={project} goBack={goBack} history={history} backIsHome={state ? state.backIsHome : null} />;
        } else {
            return "";
        }
    }
);
