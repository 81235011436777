import React, { useEffect, useState } from "react";
import Presenter from "./news-detail.presenter";
import { withRouter } from "react-router-dom";
import { isoftlab } from "../../../../api";

export default withRouter(
    ({
        history,
        history: { goBack },
        location: { state },
    }) => {
        const useNews = () => {
            const [news, setNews] = useState(null);

            const init = async () => {
                if (!state || !state.data) {
                    let id = history.location.pathname.split("/").pop();
                    const {data: prevData} = await isoftlab.getNews();
                    const data = [...prevData.fixed, ...prevData.unfixed];
                    const detailData = data.filter(d => d["_id"] === id);

                    if (detailData.length > 0) {
                        setNews(detailData[0]);
                    } else {
                        history.push("/error");
                    }
                } else {
                    setNews(state.data);
                    window.scrollTo(0, 0);
                }
            }

            useEffect(() => {
                init();
            }, []);

            return { news };
        };

        const { news } = useNews();
        
        return <Presenter news={news} goBack={goBack} history={history} backIsHome={state ? state.backIsHome : null} />;
    }
);
