import React, { useEffect, useState } from "react";
import Presenter from "./album-detail.presenter";
import { withRouter } from "react-router-dom";
import { isoftlab } from "../../../../api";

export default withRouter(
    ({
        history,
        history: { goBack },
        location: { state },
    }) => {
        const useAlbum = () => {
            const [album, setAlbum] = useState(null);

            const init = async () => {
                if (!state || !state.data) {
                    let id = history.location.pathname.split("/").pop();
                    const {data} = await isoftlab.getAlbum();
                    const detailData = data.filter(d => d["_id"] === id);

                    if (detailData.length > 0) {
                        setAlbum(detailData[0]);
                    } else {
                        history.push("/error");
                    }
                } else {
                    setAlbum(state.data);
                    window.scrollTo(0, 0);
                }
            }

            useEffect(() => {
                init();
            }, []);

            return { album };
        };

        const { album } = useAlbum();

        return <Presenter album={album} goBack={goBack} history={history} backIsHome={state ? state.backIsHome : null} />;
    }
);
