/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import PageTitle from "../../../Components/PageTitle";
import styled from "styled-components";
import PublicationCard from "../../../Components/PublicationCard";

const ItemGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 70px;
    margin-top: 150px;

    @media only screen and (max-width: 1000px) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 40px;
    }

    @media only screen and (max-width: 865px) {
        margin-top: 50px;
    }
`;

export default ({ viewData }) => {
    return (
        <Page title="Album">
            <div className="inner">
                <PageTitle title="Selected Paper" />

                <ItemGrid>
                    {viewData &&
                        viewData.map((item, idx) => (
                            <PublicationCard item={item} key={item._id} />
                        ))}
                </ItemGrid>
            </div>
        </Page>
    );
};
