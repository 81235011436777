import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { isoftlab } from "../../../api";
import Presenter from "./livedemo-detail.presenter";

export default withRouter(({
  history,
  history: {goBack},
  location: {state}
}) => {
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      if (!state?.data) {
        const id = history.location.pathname.split("/").pop();
        const {data} = await isoftlab.getLiveDemo();
        const detailData = data.filter(d => d._id === id);

        if (detailData.length > 0) {
          setData(detailData[0]);
        } else {
          history.push("/error");
        }
      } else {
        setData(state.data);
        window.scrollTo(0, 0)
      }
    })();
  }, [setData]);

  return <Presenter data={data} goBack={goBack} history={history} backIsHome={!state?.data} />
});