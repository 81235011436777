import React, { useEffect, useState } from "react";
import Presenter from "./resource-detail.presenter";
import { withRouter } from "react-router-dom";

export default withRouter(
    ({
        history,
        history: {goBack},
        location: { state }
    }) => {
        const useProject = () => {
            const [resource, setResource] = useState(null);

            useEffect(() => {
                if (!state.data) {
                    history.push("/error");
                } else {
                    setResource(state.data);
                    window.scrollTo(0, 0);
                }
            }, []);

            return { resource };
        };

        const { resource } = useProject();
        
        return <Presenter resource={resource} goBack={goBack} history={history} backIsHome={state.backIsHome ? state.backIsHome : false} />;
    }
);
