/* eslint-disable import/no-anonymous-default-export */
import Page from "../../../Components/Page";
import PageController from "../../../Components/PageController";
import TotalBox from "../../../Components/TotalBox";
import SearchBox from "../../../Components/SearchBox";
import styled from "styled-components";
import { Link } from "react-router-dom";

import "github-markdown-css";
import { Markdown } from "../../../utils";

const NewsContainer = styled.div`
    /* margin-top: 10px; */
`;

const NewsBox = styled.div`
    padding: 20px 0;
    display: flex;
    height: 200px;

    & > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &:hover .newsTitle > span:last-child {
        text-decoration: underline;
        color: #507cf2;
    }

    border-bottom: 1px solid #bcbec4;
`;

const NewsTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 17px;
    position: relative;
    color: #343c50;

    & span.newsPin {
        color: #507cf2;
        font-size: 26px;
        margin-right: 10px;
    }

    & span:last-child {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
`;

const NewsImage = styled.div`
    min-width: 250px;
    margin-left: 30px;
    height: 100%;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    @media only screen and (max-width: 850px) {
        display: none;
    }
`;

const NewsContent = styled.div`
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    * {
        font-family: inherit !important;
        font-weight: inherit !important;
        font-size: 14px;
        color: #7e838f !important;
    }
`;

const NewsDate = styled.span`
    font-size: 13px;
    color: #7e838f;
`;

export default ({
    filteredData,
    fixedData,
    viewData,
    currentPage,
    pageList,
    keyword,
    searchInput,
    clickPageNum,
    clickNextBtn,
    clickPrevBtn,
    clickLastBtn,
    clickFirstBtn,
    clickCard,
    handleSearch,
}) => {
    return (
        <Page>
            <div className="inner">
                <SearchBox
                    handleSearch={handleSearch}
                    searchInput={searchInput}
                    initKeyword={keyword}
                />
                {filteredData && (
                    <TotalBox
                        total={filteredData.length + fixedData.length}
                        isFilltered={keyword && keyword !== ""}
                    />
                )}
                <NewsContainer>
                    {fixedData &&
                        fixedData.map((news, idx) => (
                            <Link
                                key={news._id}
                                to={{
                                    pathname: `/board/news/contents/${news._id}`,
                                    state: {
                                        data: news,
                                        page: currentPage,
                                        keyword,
                                        backIsHome: false
                                    },
                                }}
                            >
                                <NewsBox>
                                    <div>
                                        <div>
                                            <NewsTitle className="newsTitle">
                                                <span className="material-icons-outlined newsPin">
                                                    push_pin
                                                </span>
                                                <span>{news.Title}</span>
                                            </NewsTitle>
                                            <NewsContent>
                                                {news["content_data"] && (
                                                    <Markdown
                                                        text={
                                                            news["content_data"]
                                                        }
                                                    />
                                                )}
                                            </NewsContent>
                                        </div>
                                        <NewsDate>{news.Date}</NewsDate>
                                    </div>
                                    {news.Image && (
                                        <NewsImage>
                                            <img
                                                src={news.Image}
                                                alt="뉴스 이미지"
                                            />
                                        </NewsImage>
                                    )}
                                </NewsBox>
                            </Link>
                        ))}
                    {viewData &&
                        viewData.map((news, idx) => (
                            <Link
                                key={news._id}
                                to={{
                                    pathname: `/board/news/contents/${news._id}`,
                                    state: {
                                        data: news,
                                        page: currentPage,
                                        keyword,
                                        backIsHome: false
                                    },
                                }}
                                onClick={clickCard}
                            >
                                <NewsBox>
                                    <div>
                                        <div>
                                            <NewsTitle className="newsTitle">
                                                <span>{news.Title}</span>
                                            </NewsTitle>
                                            <NewsContent>
                                                {news["content_data"] && (
                                                    <Markdown
                                                        text={
                                                            news["content_data"]
                                                        }
                                                    />
                                                )}
                                            </NewsContent>
                                        </div>
                                        <NewsDate>{news.Date}</NewsDate>
                                    </div>
                                    {news.img && (
                                        <NewsImage>
                                            <img
                                                src={news.img}
                                                alt="뉴스 이미지"
                                            />
                                        </NewsImage>
                                    )}
                                </NewsBox>
                            </Link>
                        ))}
                </NewsContainer>
                <PageController
                    clickFirstBtn={clickFirstBtn}
                    clickLastBtn={clickLastBtn}
                    clickNextBtn={clickNextBtn}
                    clickPageNum={clickPageNum}
                    clickPrevBtn={clickPrevBtn}
                    filteredData={filteredData}
                    currentPage={currentPage}
                    pageList={pageList}
                />
            </div>
        </Page>
    );
};
