/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import "github-markdown-css";
import { Markdown } from "../utils";

const Date = styled.div`
    color: #7e838f;
    margin-bottom: 10px;
    border-bottom: 1px solid #595f6f;
    padding-bottom: 10px;
`;

const Title = styled.div`
    padding-bottom: 14px;
    font-size: 23px;
    font-weight: 600;
    color: #343c50;
`;

const Content = styled.div`
    padding: 40px 0;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    color: #343c50;
    & p {
        color: #343c50;
        width: 100%;
        font-size: 17px;
        padding: 30px 0;
    }
`;

const Img = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    & img {
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
`;

const BackBtnBox = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    border-top: 1px solid #595f6f;
    padding-bottom: 20px;
`;

const BackBtn = styled.button`
    background-color: #507cf2;
    border: 0;
    outline: 0;
    color: #fff;
    width: 120px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
`;

const ItemCategory = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    & > li {
        font-size: 13px;
        background-color: #769af2;
        color: #fff;
        padding: 1px 10px;
        border-radius: 100px;
        list-style-type: none;
    }

    & > li.sponsored {
        background-color: #f6b33f;
    }

    /* & > li:not(:last-child) {
        margin-right: 13px;
    } */

    margin-bottom: 10px;
`;

const ItemInfo = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    & > li {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #7e838f;

        & > span:first-child {
            display: block;
            width: 24px;
            color: #769af2;
            font-size: 18px;
            margin-right: 5px;
        }

        &:not(:last-child) {
            margin-right: 32px;
        }
    }
    padding-bottom: 10px;
    border-bottom: 1px solid #595f6f;
`;

const MarkdownWrap = styled.div`
    width: 100%;
    padding-bottom: 40px;
    overflow: auto;
`;

const MarkdownBox = styled.div`
    width: 1240px;
    min-width: 1240px;
    max-width: 1240px;
`;

const Hashtag = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #7e838f;

    & div {
        font-size: 15px;

        & > span:first-child {
            margin-right: 2px;
            color: #507cf2;
        }

        &:hover > span:last-child {
            color: #595f6f;
            text-decoration: underline;
        }
    }

    & a:not(:last-child) {
        margin-right: 12px;
    }
`;

const InfoBox = styled.div`
    margin-top: 100px;
    margin-bottom: 79px;
    width: 100%;

    & > div:first-child {
        display: flex;
        align-items: center;
        & > li {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #7e838f;
            flex-wrap: wrap;

            & > span:first-child {
                display: block;
                width: 24px;
                color: #769af2;
                font-size: 18px;
            }

            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }

    a:hover {
        span:first-child {
            text-decoration: underline;
        }
    }
`;

// const MarkdownBox = styled.div`
//     margin-top: 54px;
//     margin-bottom: 60px;
//     padding: 0 8%;

//     p, li, a, span {
//         font-family: "Poppins", "Noto Sans KR", sans-serif;
//         font-size: 16px;
//     }
// `;

export default ({ data, goBack, backIsHome, history }) => {
    return (
        <>
            <ItemCategory>
                {data.Sponsored === "YES" && (
                    <li className="sponsored">Sponsored</li>
                )}
                {data.Category &&
                    data.Category.map((category) => {
                        return category === "" ? null : <li key={category}>{category}</li>
                    })}
            </ItemCategory>
            <Title>{data.Title}</Title>
            <ItemInfo>
                <li>
                    {data.Date}
                </li>
            </ItemInfo>
            <Content>
                <MarkdownWrap>
                    <MarkdownBox>
                        <Markdown text={data.Markdown} />
                    </MarkdownBox>
                </MarkdownWrap>
                <InfoBox>
                    <Hashtag>
                        {data.Keyword &&
                            data.Keyword.map((h, i) => (
                                <Link
                                    key={i}
                                    to={`/resource?page=1&keyword=${h}`}
                                >
                                    <div>
                                        <span>#</span>
                                        <span>{h}</span>
                                    </div>
                                </Link>
                            ))}
                    </Hashtag>
                </InfoBox>
            </Content>
            <BackBtnBox>
                <BackBtn
                    onClick={() => {
                        backIsHome ? history.push("/resource/?page=1") : goBack()
                }}>목록</BackBtn>
            </BackBtnBox>
        </>
    );
};
