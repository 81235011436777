/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// Home
import Home from "../Routes/Home";
// Members
import Members from "../Routes/Members";
import Professor from "../Routes/Members/Professor";
import Students from "../Routes/Members/Students";
import Alumni from "../Routes/Members/Alumni";
// Research
import Research from "../Routes/Research";
import Areas from "../Routes/Research/Areas";
import Projects from "../Routes/Research/Projects";
import ProjectsDetail from "../Routes/Research/Projects/Detail";
import Seminar from "../Routes/Research/Seminar";
import SeminarDetail from "../Routes/Research/Seminar/Detail";
// Publications
import PublicationsTop from "../Routes/Publications";
import Publications from "../Routes/Publications/Publications";
import SelectedPaper from "../Routes/Publications/SelectedPaper";
// Board
import Board from "../Routes/Board";
import News from "../Routes/Board/News";
import NewsDetail from "../Routes/Board/News/Detail";
import Album from "../Routes/Board/Album";
import AlbumDetail from "../Routes/Board/Album/Detail";
// Live Demo
import Livedemo from "../Routes/Livedemo";
import LivedemoDetail from "../Routes/Livedemo/Detail";
// Resource
import Resource from "../Routes/Resource";
import ResourceDetail from "../Routes/Resource/Detail";
// Contact
import Contact from "../Routes/Contact";
import Apply from "../Routes/Contact/Apply";
import FAQ from "../Routes/Contact/FAQ";
// 404 error
import ErrorPage from "../Routes/Error";

// header
import Header from "./Header";

export default ({projectTypeState}) => (
    <BrowserRouter>
        <Header />
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/members" exact component={Members} />
            <Route path="/members/professor" exact component={Professor} />
            <Route path="/members/students" exact component={Students} />
            <Route path="/members/alumni" exact component={Alumni} />
            <Route path="/research" exact component={Research} />
            <Route path="/research/research_areas" exact component={Areas} />
            <Route path="/research/projects/" exact render={(props) => <Projects {...props} projectTypeState={projectTypeState} />} />
            <Route
                path="/research/projects/contents/:id"
                exact
                component={ProjectsDetail}
            />
            <Route path="/research/seminar" exact component={Seminar} />
            <Route
                path="/research/seminar/contents/:id"
                exact
                component={SeminarDetail}
            />
            <Route path="/publications" exact component={PublicationsTop} />
            <Route
                path="/publications/publications"
                exact
                component={Publications}
            />
            <Route
                path="/publications/selected_paper"
                exact
                component={SelectedPaper}
            />
            <Route path="/board" exact component={Board} />
            <Route path="/board/news" exact component={News} />
            <Route
                path="/board/news/contents/:id"
                exact
                component={NewsDetail}
            />
            <Route path="/board/album" exact component={Album} />
            <Route
                path="/board/album/contents/:id"
                exact
                component={AlbumDetail}
            />
            <Route path="/livedemo" exact component={Livedemo} />
            <Route path="/livedemo/contents/:id" exact component={LivedemoDetail} />
            <Route path="/resource" exact component={Resource} />
            <Route path="/resource/contents/:id" exact component={ResourceDetail} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/contact/apply" exact component={Apply} />
            <Route path="/contact/faq" exact component={FAQ} />
            <Route path="/error" exact component={ErrorPage} />
            <Redirect to="/error" />
        </Switch>
    </BrowserRouter>
);
