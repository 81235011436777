/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import "github-markdown-css";
import { Markdown } from "../utils";

const Date = styled.div`
    color: #7e838f;
    margin-bottom: 10px;
    border-bottom: 1px solid #595f6f;
    padding-bottom: 10px;
`;

const Title = styled.div`
    padding-bottom: 14px;
    font-size: 23px;
    font-weight: 600;
    color: #343c50;
`;

const Content = styled.div`
    padding: 40px 0;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    color: #343c50;
    & p {
        color: #343c50;
        width: 100%;
        font-size: 17px;
        padding: 30px 0;
    }
`;

const Img = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    & img {
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
`;

const BackBtnBox = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    border-top: 1px solid #595f6f;
    padding-bottom: 20px;
`;

const BackBtn = styled.button`
    background-color: #507cf2;
    border: 0;
    outline: 0;
    color: #fff;
    width: 120px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
`;

const ItemCategory = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    & > li {
        font-size: 13px;
        background-color: #769af2;
        color: #fff;
        padding: 1px 10px;
        border-radius: 100px;
        list-style-type: none;
    }

    & > li.sponsored {
        background-color: #f6b33f;
    }

    /* & > li:not(:last-child) {
        margin-right: 13px;
    } */

    margin-bottom: 10px;
`;

const ItemInfo = styled.ul`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    & > li {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #7e838f;

        & > span:first-child {
            display: block;
            width: 24px;
            color: #769af2;
            font-size: 18px;
            margin-right: 5px;
        }

        &:not(:last-child) {
            margin-right: 32px;
        }
    }
    padding-bottom: 10px;
    border-bottom: 1px solid #595f6f;
`;

const Hashtag = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #7e838f;

    & div {
        font-size: 15px;

        & > span:first-child {
            margin-right: 2px;
            color: #507cf2;
        }

        &:hover > span:last-child {
            color: #595f6f;
            text-decoration: underline;
        }
    }

    & a:not(:last-child) {
        margin-right: 12px;
    }
`;

const InfoBox = styled.div`
    margin-top: 100px;
    margin-bottom: 79px;
    width: 100%;

    & > div:first-child {
        display: flex;
        align-items: center;
        & > li {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #7e838f;
            flex-wrap: wrap;

            & > span:first-child {
                display: block;
                width: 24px;
                color: #769af2;
                font-size: 18px;
            }

            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }

    a:hover {
        span:first-child {
            text-decoration: underline;
        }
    }
`;

const MarkdownBox = styled.div`
    margin-top: 54px;
    margin-bottom: 60px;
    padding: 0 8%;

    p, li, a, span {
        font-family: "Poppins", "Noto Sans KR", sans-serif;
        font-size: 16px;
    }
`;

export default ({ data, goBack, backIsHome, history }) => {
    return (
        <>
            <ItemCategory>
                {data.Sponsored === "YES" && (
                    <li className="sponsored">Sponsored</li>
                )}
                {data.Category &&
                    data.Category.map((category) => (
                        <li key={category}>{category}</li>
                    ))}
            </ItemCategory>
            <Title>{data.Title}</Title>
            <ItemInfo>
                <li>
                    <span className="material-icons-outlined">domain</span>
                    <span>{data.Institution}</span>
                </li>
                <li>
                    <span className="material-icons-outlined">
                        calendar_today
                    </span>
                    <span>{data.Date.replace("->", " ~ ")}</span>
                </li>
            </ItemInfo>
            <Content>
                {/* {data.Image && data.Image.map((img) => (
                <Img key={img}>
                    <img src={img} alt="기사이미지" />
                </Img>
            ))} */}
                {data.Image && (
                    <Img key={data.Image}>
                        <img src={data.Image} alt="기사이미지" />
                    </Img>
                )}
                <MarkdownBox>
                    {data["content_data"] && (
                        <Markdown text={data["content_data"]} />
                    )}
                </MarkdownBox>
                <InfoBox>
                    <div>
                        <li>
                            <span className="material-icons-outlined">
                                people
                            </span>
                            {data.Participatents &&
                                data.Participatents.map((people, idx) => {
                                    const [name, email] = people
                                        .replace(")", "")
                                        .split("(");
                                    return (
                                        <Link
                                            to={`/members/students?id=${btoa(
                                                email
                                            )}`}
                                            key={people}
                                        >
                                            <span>{people}</span>
                                            {idx <
                                                data.Participatents.length -
                                                    1 && <span>,&nbsp;</span>}
                                        </Link>
                                    );
                                })}
                        </li>
                    </div>
                    <Hashtag>
                        {data.Keyword &&
                            data.Keyword.map((h, i) => (
                                <Link
                                    key={i}
                                    to={`/research/projects?page=1&keyword=${h}`}
                                >
                                    <div>
                                        <span>#</span>
                                        <span>{h}</span>
                                    </div>
                                </Link>
                            ))}
                    </Hashtag>
                </InfoBox>
            </Content>
            <BackBtnBox>
                <BackBtn
                    onClick={() => {
                        backIsHome || backIsHome === null ? history.push("/research/projects?page=1") : goBack()
                }}>목록</BackBtn>
            </BackBtnBox>
        </>
    );
};
