import React, {useState, useEffect} from "react";
import Presenter from "./seminar.presenter";
import { usePaging } from "../../../utils";
import { isoftlab } from "../../../api";
import { withRouter } from "react-router-dom";

const MAX_VIEW_PAGE = 5;
const MAX_VIEW_DATA = 12;
const searchOption = {
    search: ["Title", "Date", "Presenter"],
    category: "Tags"
}

const categoryOption = [
    "Paper", "Lecture", "Book", "Skill"
];

export default withRouter((props) => {
    return (
        <Presenter
            {...usePaging({
                dataFn: isoftlab.getSeminar,
                maxViewPage: MAX_VIEW_PAGE,
                maxViewData: MAX_VIEW_DATA,
                searchOption,
                urlProps: props
            })}
            categoryOption={categoryOption}
        />
    )
});