import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Item = styled.div`
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #bcbec4;
    cursor: pointer;
    position: relative;

    & > .detail {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 14px;
        text-decoration: underline;
        color: #fff;
        display: none;
        z-index: 10;
    }

    & .itemContent {
        background-color: #ffffff;

        & > * {
            opacity: 0.8;
        }
    }

    &:hover {
        border: 1px solid #507cf2;

        & .itemContent > * {
            opacity: 1;
        }

        & > .imgBox {
            background-color: #507cf2;
            & > img,
            & > .tempImg {
                opacity: 0.4;
            } 
        }

        & > .detail {
            display: block;
        }

        /* & h4 {
            font-family: "Apple SD Gothic Neo", sans-serif;
        } */
    }
`;

const ImgBox = styled.div`
    width: 100%;
    height: 200px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 100%;
        display: block;
        transition: opacity 0.2s ease-in-out;
    }
`;

const TempImg = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: opacity 0.2s ease-in-out;
`;

const ItemContent = styled.div`
    padding: 15px;
    border-top: 1px solid #bcbec4;

    & > h4 {
        font-weight: 600;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        min-height: 77px;
    }

    & > ul {
        & > li {
            display: flex;
            align-items: center;
            font-size: 14px;

            & > span:first-child {
                display: block;
                width: 24px;
                color: #507cf2;
                font-size: 18px;
            }

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }
`;

const ItemCategory = styled.ul`
    display: flex;
    & > li {
        font-size: 13px;
        background-color: #507cf2;
        color: #fff;
        padding: 1px 10px;
        border-radius: 100px;
    }

    & > li:not(:last-child) {
        margin-right: 13px;
    }
`;

export default ({item, page, category, keyword, clickCard, backIsHome}) => (
    <Link to={{
        pathname: `/research/seminar/contents/${item._id}`,
        state: {
            data: item,
            page,
            category,
            keyword,
            backIsHome: backIsHome ? backIsHome : false
        }
    }}
        onClick={clickCard}
    >
        <Item>
            <div className="detail">자세히 보기</div>
            <ImgBox className="imgBox">
                <img src={item.Image} />
            </ImgBox>
            <ItemContent className="itemContent">
                <ItemCategory>
                    {item.Tags.map((tag) => (
                        <li key={`${item._id}${tag}`}>{tag}</li>
                    ))}
                </ItemCategory>
                <h4>{item.Title}</h4>
                <ul>
                    <li>
                        <span className="material-icons-outlined">people</span>
                        <span>{item.Presenter.join(", ")}</span>
                    </li>
                    <li>
                        <span className="material-icons-outlined">calendar_today</span>
                        <span>{item.Date}</span>
                    </li>
                </ul>
            </ItemContent>
        </Item>
    </Link>
)