/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import ResourcePost from "../../../Components/ResourcePost";

export default ({ resource, goBack, history, backIsHome }) => (
    <Page>
        <div className="inner">
            {resource &&
                <ResourcePost
                    data={resource}
                    goBack={goBack}
                    history={history}
                    backIsHome={backIsHome}
                />}
        </div>
    </Page>
);
