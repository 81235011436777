/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect} from "react";
import { usePaging } from "../../../utils";
import Presenter from "./selectedPaper.presenter";
import { isoftlab, test } from "../../../api";
import { withRouter } from "react-router-dom";


export default withRouter((props) => {
    const useData = () => {
        const [viewData, setViewData] = useState(null);

        const init = async () => {
            let {data} = await isoftlab.getPublications();
            data = data.filter((d) => {
                return d.Selected === "YES";
            })
            setViewData(data);
        }

        useEffect(() => {
            init();
        },[]);

        return {viewData}
    }
    return (
        <Presenter
            {...useData()}
        />
    )
});