import styled from "styled-components"
import Page from "../../../Components/Page";

const Container = styled.div`

`;

const Title = styled.div`
  font-size: 46px;
  color: #343c50;
  text-align: center;
  border-bottom: 1px solid #595f6f;
  padding-bottom: 100px;
  margin-bottom: 80px;
`;

const IframeBox = styled.div`
  position: relative;
  padding-top: 56%;
  width: 100%;
  height: 0;

  iframe {
      /* padding: 0 6%; */
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
  }
`;

const Content = styled.div`
  padding-bottom: 60px;
`;

const BackBtnBox = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    border-top: 1px solid #595f6f;
    padding-bottom: 20px;
`;

const BackBtn = styled.button`
    background-color: #507cf2;
    border: 0;
    outline: 0;
    color: #fff;
    width: 120px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
`;

export default ({data, goBack, history, backIsHome}) => {
  return (
    <Page>
      <div className="inner">
        {data && (
          <Container>
            <Title>
              {data.Title}
            </Title>
            <Content>
              <IframeBox>
                <iframe
                  src={data.Url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </IframeBox>
            </Content>
            <BackBtnBox>
            <BackBtn
                onClick={() => {
                    backIsHome ? history.push("/livedemo") : goBack()
            }}>목록</BackBtn>
            </BackBtnBox>
          </Container>
        )}

      </div>
    </Page>
  )
}