import React from "react";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    max-width: 300px;

    @media only screen and (max-width: 865px) {
        max-width: initial;
        display: none;
        &.mobile-show {
            display: flex;
            align-items: center;
            position: absolute;

            & > div:first-child {
                padding: 13px 20px;
                border: 1px solid #507cf2;
                border-radius: 15px;
                box-shadow: 0 2px 7px 3px rgba(0, 0, 0, 0.25);
                position: absolute;
                top: -80px;
                left: 50%;
                width: 100%;
                z-index: 100;
                transform: translateX(-50%);
            }

            & .black {
                background-color: rgba(52, 60, 80, 0.4);
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
`;

const ProfileBox = styled.div`
    font-size: 16px;
    background-color: #fff;
    width: 100%;
    max-width: 300px;

    @media only screen and (max-width: 865px) {
        width: 100%;
        max-width: 400px;
    }
`;

const MobileExitBtn = styled.div`
    display: none;

    & > span {
        color: #507cf2;
        position: relative;
        left: 5px;
        font-size: 30px;
        cursor: pointer;
    }

    @media only screen and (max-width: 865px) {
        display: block;
        text-align: right;
    }
`;

const ProfileImg = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    
    & > img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        object-position: top;
        display: block;
        max-width: 400px;
    }

    @media only screen and (max-width: 865px) {
        margin-top: 30px;
        & > img {
            width: 270px;
            height: 270px;
            object-fit: cover;
            object-position: center;
        }
    }
`;

const ProfileName = styled.div`
    margin: 30px 0;
    font-weight: 600;
    & > h2 {
        font-size: 26px;
    }

    & > h3 {
        font-size: 18px;
    }

    @media only screen and (max-width: 865px) {
        margin-top: 60px;
    }
`;

const Label = styled.div`
    color: #507cf2;
    font-weight: 600;

    &.notBold {
        font-weight: 500;
    }
`;

const Profile = styled.div`
    margin-bottom: 26px;

    & > p {
        margin-top: 3px;
        margin-bottom: 8px;
    }
`;

const ProfileInfo = styled.div`
    display: grid;
    color: #595f6f;
    grid-template-columns: 75px 1fr;
    gap: 4px;
    margin-bottom: 50px;

    & > div {
        word-break: break-all;
    }

    & a {
        white-space: break-all;
    }
`;

const ProfileHashtag = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #7e838f;
    font-size: 13px;

    & > div > span:first-child {
        color: #507cf2;
        margin-right: 2px;
    }

    & > div:not(:last-child) {
        margin-right: 15px;
    }
`;

const Href = styled.a`
    &:hover {
        color: #99B9FA;
        text-decoration: underline;
    }
`;

const BigLabel = styled.div`
    color: #507cf2;
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 15px;
`;

const EduAndCareerBox = styled.div`
    &:not(:last-child) {
        margin-bottom: 50px;
    }
`;

const EduAndCareer = styled.div`
    & span {
        display: block;
    }
    & span:first-child {
        margin-bottom: 4px;
        font-weight: 600;
    }
    & span:last-child {
        color: #595f6f;
    }
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;


export default ({ profileRef, profile, isStudent, profileClose }) => {
    let linkName = profile.EngName.toLowerCase();
    linkName = linkName.replace(" ", "_");
    return (
        <Container ref={profileRef}>
            <ProfileBox>
                <MobileExitBtn>
                    <span className="material-icons" onClick={profileClose}>
                        close
                    </span>
                </MobileExitBtn>
                <ProfileImg>
                    <img src={profile.Image} />
                </ProfileImg>
                <ProfileName>
                    <h2>{profile.KorName}</h2>
                    <h3>{profile.EngName}</h3>
                </ProfileName>
                <Profile>
                    <Label>{profile.CurrentState.split(" ")[0]}</Label>
                    <p>Computer Science and Engineering,<br/>Chungnam National University</p>
                    <ProfileHashtag>
                        {(profile.Topic && profile.Topic.length !== 0) &&
                            profile.Topic.map((hashtag, idx) => (
                                <div key={idx}>
                                    <span>#</span>
                                    <span>{hashtag}</span>
                                </div>
                            ))}
                    </ProfileHashtag>
                </Profile>
                <ProfileInfo>
                    {(profile.Email && profile.Email !== "") && (
                        <>
                            <Label className="notBold">E-mail</Label>
                            <div>{profile.Email}</div>
                        </>
                    )}
                    {(profile.Github && profile.Github !== "") && (
                        <>
                            <Label className="notBold">Github</Label>
                            <div><Href href={profile.Github} target="_blank">{ `github.com/${linkName}` }</Href></div>
                        </>
                    )}
                    {(profile.Scholar && profile.Scholar !== "") && (
                        <>
                            <Label className="notBold">Scholar</Label>
                            <div><Href href={profile.Scholar} target="_blank">{ `google.scholar/${linkName}` }</Href></div>
                        </>
                    )}
                    {(profile.Blog && profile.Blog !== "") && (
                        <>
                            <Label className="notBold">Blog</Label>
                            <div><Href href={profile.Blog} target="_blank">{ `${linkName}.blog.com` }</Href></div>
                        </>
                    )}
                    {(profile.CV && profile.CV !== "" ) && (
                        <>
                            <Label className="notBold">CV</Label>
                            <div><Href href={profile.CV} target="_blank">{ `notion.com/${linkName}/CV` }</Href></div>
                        </>
                    )}
                    {(profile.Linkedin && profile.Linkedin !== "") && (
                        <>
                            <Label className="notBold">Linkedin</Label>
                            <div><Href href={profile.Linkedin} target="_blank">{ `linkedin.com/in/${linkName}` }</Href></div>
                        </>
                    )}
                </ProfileInfo>
                {((!isStudent && profile.Career && profile.Career.length !== 0) && (
                    <EduAndCareerBox>
                        <BigLabel>Current</BigLabel>
                        {profile.Career.map((career, idx) => (
                            <EduAndCareer key={idx}>
                                <span>{career.temp}</span>
                            </EduAndCareer>
                        ))}
                    </EduAndCareerBox>
                ))}
                {((profile.Education && profile.Education.length !== 0) && (
                    <EduAndCareerBox>
                        <BigLabel>Education</BigLabel>
                        {profile.Education.map((edu, idx) => (
                            <EduAndCareer key={idx}>
                                <span>{edu.degree}</span>
                                <span>{ edu.description }</span>
                            </EduAndCareer>
                        ))}
                    </EduAndCareerBox>
                ))}
                {((isStudent && profile.Career && profile.Career.length !== 0) && (
                    <EduAndCareerBox>
                        <BigLabel>Career</BigLabel>
                        {profile.Career.map((car, idx) => (
                            <EduAndCareer key={idx}>
                                <span>{car.Company}</span>
                                <span>{ car.date }</span>
                            </EduAndCareer>
                        ))}
                    </EduAndCareerBox>
                ))}
            </ProfileBox>
            <div className="black" onClick={profileClose}></div>
        </Container>
    )
}