import React, { useState, useEffect } from "react";
import Presenter from "./faq.presenter";
import { isoftlab } from "../../../api";

export default () => {
    const useFaq = () => {
        const [faqs, setFaqs] = useState(null);

        const init = async () => {
            const {data} = await isoftlab.getFAQ();
            setFaqs(data);
        }

        useEffect(() => {
            init();

            // const currentPath = document.querySelectorAll(".currentPath");
            //     currentPath.forEach(path => {
            //         path.addEventListener("click", () => {
            //         });
            //     })
        }, [])

        return {
            faqs
        }
    }

    const { faqs } = useFaq();
    return <Presenter faqs={faqs} />;
}