import React, {useState, useEffect} from "react";
import Presenter from "./areas.presenter";
import { isoftlab } from "../../../api";

export default () => {
    const useArea = () => {
        const [data, setData] = useState(null);

        const init = async () => {
            const {data: areasData} = await isoftlab.getResearchArea();
            const { data: introData } = await isoftlab.getIntroduction();
            setData({
                areasData,
                introData
            });
        }

        useEffect(() => {
            init();
        }, []);

        return {
            data
        }
    }

    const {data} = useArea();
    return <Presenter data={data} />;
}
