import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Item = styled.div`
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #bcbec4;
    cursor: pointer;
    position: relative;
    height: 100%;

    & > .detail {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 14px;
        text-decoration: underline;
        color: #fff;
        display: none;
        z-index: 10;
    }

    & .itemContent {
        background-color: #ffffff;

        & > * {
            opacity: 0.8;
        }
    }

    &:hover {
        border: 1px solid #507cf2;

        & .itemContent > * {
            opacity: 1;
        }

        & .listItemContent h4 {
            color: #507cf2;
        }

        & > .imgBox {
            background-color: #507cf2;
            & > img {
                opacity: 0.4;
            }
        }

        & > .detail {
            display: block;
        }

        /* & h4 {
            font-family: "Apple SD Gothic Neo", sans-serif;
        } */
    }

    li {
        list-style-type: none;
    }
`;

const ImgBox = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    /* background-color: #507cf2; */

    & > img {
        /* width: 100%; */
        object-fit: cover;
        object-position: center;
        max-width: 100%;
        height: 100%;
        display: block;
        /* transition: opacity 0.2s ease-in-out; */
    }
`;

const ItemContent = styled.div`
    padding: 15px;
    border-top: 1px solid #bcbec4;

    & > h4 {
        font-weight: 600;
        margin-top: 7px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    & > ul {
        & > li {
            display: flex;
            align-items: center;
            font-size: 14px;

            & > span:first-child {
                display: block;
                width: 24px;
                color: #507cf2;
                font-size: 18px;
            }

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }
`;

const ItemCategory = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 7px 0;

    & > li {
        font-size: 13px;
        background-color: #507cf2;
        color: #fff;
        padding: 1px 10px;
        border-radius: 100px;
        white-space: nowrap;
    }

    & > li.sponsored {
        background-color: #f6b33f;
    }

    & > li:not(:last-child) {
        margin-right: 13px;
    }
`;

const ItemHashtag = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%; */

    & > div {
        /* & > * {
            white-space: nowrap;
        }
        width: 100% */
        font-size: 12px;

        & > span:first-child {
            margin-right: 2px;
            color: #507cf2;
        }
    }

    & > div:not(:last-child) {
        margin-right: 12px;
    }
`;

const ListItemContent = styled.div`
    padding: 15px;

    h4 {
        color: #343c50;
        font-size: 20px;
        font-weight: 600;
        margin-top: 7px;
    }

    p {
        font-size: 12px;
        margin-top: 14px;
    }
`

export default ({ clone, item, page, category, keyword, clickCard, backIsHome, listView=false }) => (
    <Link
        className={clone ? clone : ""}
        to={{
            pathname: `/research/projects/contents/${item._id}`,
            state: {
                data: item,
                page,
                category,
                keyword,
                backIsHome: backIsHome ? backIsHome : false
            },
        }}
        onClick={clickCard}
    >
        <Item>
            {!listView ? (
                <>
                    <div className="detail">자세히 보기</div>
                    <ImgBox className="imgBox">
                        {item.Image && <img src={item.Image} />}
                    </ImgBox>
                    <ItemContent className="itemContent">
                        <div>
                            <ItemCategory>
                                <li className="sponsored">{ item.Sponsored }</li>
                                {item.Category &&
                                    item.Category.map((c) => <li key={c}>{c}</li>)}
                            </ItemCategory>
                        </div>
                        <h4>{item.Title}</h4>
                        <ul>
                            <li>
                                <span className="material-icons-outlined">
                                    calendar_today
                                </span>
                                <span>{item.Date.replace("->", " ~ ")}</span>
                            </li>
                            {item.Sponsored === "Funded" ? (
                                <li>
                                    <span className="material-icons-outlined">
                                        domain
                                    </span>
                                    <span>{item.Institution}</span>
                                </li>
                            ) : (
                                <li>
                                    <span className="material-icons-outlined">
                                        home
                                    </span>
                                    <span>ISoft Lab</span>
                                </li>
                            )}
                        </ul>
                        <ItemHashtag>
                            {item.Keyword &&
                                item.Keyword.map((h, i) => (
                                    <div key={i}>
                                        <span>#</span>
                                        <span>{h}</span>
                                    </div>
                                ))}
                        </ItemHashtag>
                    </ItemContent>
                </>
            ) : (
                    <ListItemContent className="itemContent listItemContent">
                        <div>
                            <ItemCategory>
                                <li className="sponsored">{ item.Sponsored }</li>
                                {item.Category &&
                                    item.Category.map((c) => <li key={c}>{c}</li>)}
                            </ItemCategory>
                        </div>
                        <h4>{item.Title}</h4>
                        <p>{item.Title}, {item.Institution}, {item.Date.replace(" -> ", "~")}, { item.ProjectsNum }</p>
                    </ListItemContent>
            )}
        </Item>
    </Link>
);
