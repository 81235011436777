import styled from "styled-components";
import { useRef } from "react";

const AnimationView = styled.div`
    width: 100%;
    overflow: hidden;
`;
const AnimationBox = styled.div`
    display: flex;
    gap: 35px;
    flex-direction: ${({ initDirection }) => initDirection};
    touch-action: pan-x;

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    & > a {
        display: block;
        width: 100%;
    }

    & .clone {
        display: none;
    }

    @media only screen and (max-width: 865px) {
        flex-direction: row;
        width: 700%;
        gap: 0;
        & .clone {
            display: block;
        }
    }
`;

const AnimationCircle = styled.div`
    display: none;
    gap: 10px;
    justify-content: center;
    margin-top: 18px;

    & div {
        width: 7px;
        height: 7px;
        background-color: #bcbec4;
        border-radius: 100%;
    }

    & div.current {
        background-color: #769af2;
    }

    @media only screen and (max-width: 865px) {
        display: flex;
    }
`;

const LOOP_TIME = 4;
const T_DURATION = 0.25;
const ITEM_COUNT = 7;

let start = false;

export default ({ initDirection, children }) => {
    const animationView = useRef();
    const animationBox = useRef();
    const animationCircle = useRef();

    let cnt = 2;
    let interval;
    let timeout;
    let touchAble = true;
    let touchstart = false;
    let mouseX;
    let touchStartCurrentX;
    let prevX;
    let mouseDirection;

    const getTranslateStyle = (width, transition) => {
        return `
          transform: translate3d(-${width}px, 0, 0);
          transition: ${transition ? `${T_DURATION}s` : "0s"};
        `;
    }

    const getCurrentX = () => {
        const animationViewX = animationView.current.getBoundingClientRect().x;
        const animationBoxX = animationBox.current.getBoundingClientRect().x;
      
        return animationBoxX - animationViewX;
    }

    const getWidth = () => {
        return animationView.current.clientWidth;
    }

    const setAnimationCircle = (current) => {
        if (animationCircle.current) {
            const circles = animationCircle.current.children;

            for (let i = 0; i < circles.length; i++) {
                if (i === current) {
                    if (!circles[i].classList.contains("current")) {
                        circles[i].classList.add("current");
                    }
                } else {
                    if (circles[i].classList.contains("current")) {
                        circles[i].classList.remove("current");
                    }
                }
            }
        }
    }

    const setAnimation = () => {
        interval = setInterval(() => {
            try {
                const width = getWidth();
                cnt++;
                if (cnt >= ITEM_COUNT - 1) {
                    cnt = 2;
                    animationBox.current.style = getTranslateStyle(width * cnt, false);
                    setTimeout(() => {
                    cnt++;
                    animationBox.current.style = getTranslateStyle(width * cnt, true);
                    }, 1);
                } else {
                    animationBox.current.style = getTranslateStyle(width * cnt, true);
                }

                let circleIdx = cnt - 2;
                circleIdx = circleIdx === 0 ? 1 : circleIdx;
                circleIdx = circleIdx === 3 ? 0 : circleIdx;
                setAnimationCircle(circleIdx);
            } catch (e) {
                clearInterval(interval);
            }
        }, LOOP_TIME * 1000);
    }

    const handleTouchStart = (event) => {
        event.preventDefault();
        if (touchAble) {
            touchstart = true;
            mouseX = event.clientX;
            prevX = mouseX;
            touchStartCurrentX = getCurrentX();
            
            if (cnt === 5) {
                const width = getWidth();
                cnt = 2;
                animationBox.current.style = getTranslateStyle(width * cnt, false);
            }
            animationBox.current.style = `transform: translate3d(${getCurrentX()}px, 0, 0);`;
          
            clearTimeout(timeout);
            clearInterval(interval);
        }
    }

    const handleTouchEnd = (event) => {
        event.preventDefault();
        if (touchstart) {
            touchstart = false;
            touchAble = false;
            const width = getWidth();
            if (mouseDirection === 1) {
                cnt++;
                animationBox.current.style = getTranslateStyle(width * cnt, true);
                timeout = setTimeout(() => {
                    if (animationBox.current) {
                        if (cnt >= ITEM_COUNT - 2) {
                        cnt = ITEM_COUNT - cnt;
                        animationBox.current.style = getTranslateStyle(width * cnt, false);
                        }
                        setAnimation();
                        touchAble = true;
                    }
                }, T_DURATION * 1000);
                let circleIdx = cnt - 2;
                circleIdx = circleIdx >= 3 ? 0 : circleIdx;
                setAnimationCircle(circleIdx);
            } else if (mouseDirection === 0) {
                cnt--;
                animationBox.current.style = getTranslateStyle(width * cnt, true);
                timeout = setTimeout(() => {
                    if (animationBox.current) {
                        if (cnt <= 1) {
                        cnt = ITEM_COUNT - cnt - 2;
                        animationBox.current.style = getTranslateStyle(width * cnt, false);
                        }
                        setAnimation();
                        touchAble = true;
                    }
                }, T_DURATION * 1000);
                let circleIdx = cnt - 2;
                circleIdx = circleIdx === -1 ? 2 : circleIdx;
                setAnimationCircle(circleIdx);
            }
    
            timeout = setTimeout(() => {
                mouseDirection = null;
            }, 1);
        }
    }
      
    const handleSlide = (event) => {
        event.preventDefault();
        if (touchstart) {
            const currentMouseX = event.clientX;
            
            if (currentMouseX) {
                if (currentMouseX - prevX <= 0) {
                    mouseDirection = 1; // 왼쪽
                } else {
                    mouseDirection = 0; // 오른쪽
                }

                const gap = mouseX - currentMouseX;
                animationBox.current.style = `transform: translate3d(${
                    touchStartCurrentX - gap
                }px, 0, 0);`;
            
                prevX = currentMouseX;
            }
        }
    }

    const handleClick = (event) => {
        if (mouseDirection === 1 || mouseDirection === 0) {
            event.preventDefault();
        }
    }

    const addEvent = () => {
        animationBox.current.addEventListener("pointerdown", handleTouchStart);
        animationBox.current.addEventListener("pointerup", handleTouchEnd);
        animationBox.current.addEventListener("pointerleave", handleTouchEnd);
        animationBox.current.addEventListener("pointermove", handleSlide);
        animationBox.current.addEventListener("touchmove", handleSlide);
        animationBox.current.addEventListener("click", handleClick);
    }

    const removeEvent = () => {
        animationBox.current.removeEventListener("pointerdown", handleTouchStart);
        animationBox.current.removeEventListener("pointerup", handleTouchEnd);
        animationBox.current.removeEventListener("pointerleave", handleTouchEnd);
        animationBox.current.removeEventListener("pointermove", handleSlide);
        animationBox.current.removeEventListener("touchmove", handleSlide);
        animationBox.current.removeEventListener("click", handleClick);
    }

    const resize = () => {
        if (animationView.current && animationBox.current && animationCircle.current) {
            clearInterval(interval);
            removeEvent();

            if (window.innerWidth < 865) {
                addEvent();
                animationBox.current.style = getTranslateStyle(getWidth() * cnt, false);
                setAnimation();
                
                if (cnt === 5) {
                    setAnimationCircle(0);
                } else {
                    setAnimationCircle(cnt - 2);
                }
            } else {
                cnt = 2;
                animationBox.current.style = "";
            }
        }
    }

    window.addEventListener("resize", resize);
    resize();

    return (
        <AnimationView ref={animationView}>
            <AnimationBox ref={animationBox} initDirection={initDirection}>
                {children}
            </AnimationBox>
            <AnimationCircle ref={animationCircle} className="ani-circle">
                <div></div>
                <div></div>
                <div></div>
            </AnimationCircle>
        </AnimationView>
    )
}