import axios from "axios";
import dotenv from "dotenv";
import resourceData from "./Routes/Resource/resourceData";

dotenv.config();

axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export const isoftlab = {
    getProfessor: async () => {
        const data = await api.get("professor/Information");
        return data;
    },
    getStudents: async () => {
        const data = await api.get("Students/students");
        return data;
    },
    getApply: async () => {
        const data = await api.get("Contact/Contact_Apply");
        return data;
    },
    getPublications: async () => {
        const data = await api.get("Publications");
        return data;
    },
    getProject: async () => {
        const data = await api.get("Research/Research_Project");
        return data;
    },
    getSeminar: async () => {
        const data = await api.get("Research/Seminar");
        return data;
    },
    getResearchArea: async () => {
        const data = await api.get("Research/Research_Area");
        return data;
    },
    getAlumni: async () => {
        const data = await api.get("Alumni/Alumni");
        return data;
    },
    getFAQ: async () => {
        const data = await api.get("Contact/Contact_FAQ");
        return data;
    },
    getNews: async () => {
        const data = await api.get("News");
        return data;
    },
    getAlbum: async () => {
        const data = await api.get("Album");
        return data;
    },
    getResource: async () => {
        const data = await api.get("Resource/Resource");
        return data;
    },
    getIntroduction: async () => {
        const data = await api.get("Introduction/Introduction");
        return data;
    },
    getLiveDemo: async () => {
        const data = await api.get("LiveDemo/LiveDemo");
        return data;
    }
};
