import React, {useState, useEffect} from "react";
import Presenter from "./apply.presenter";
import { isoftlab } from "../../../api";

export default () => {
    const useApply = () => {
        const [data, setData] = useState(null);

        const init = async () => {
            const {data: applyData} = await isoftlab.getApply();
            const { data: introData } = await isoftlab.getIntroduction();

            setData({
                applyData,
                introData
            });
        }

        useEffect(() => {
            init();
        }, []);

        return {data};
    }

    const {data} = useApply();
    return (
        <Presenter data={data} />
    );
}