/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import PageTitle from "../../../Components/PageTitle";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
    margin-top: 160px;

    & > a {
        display: block;
    }
    & > a:not(:last-child) {
        margin-bottom: 40px;
    }
`;

const Card = styled.div`
    padding: 20px;
    display: flex;
    border: 1px solid #bcbec4;
    border-radius: 25px;
    gap: 38px;

    &:hover {
        border: 1px solid #507cf2;
        box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.1);

        h2 {
            color: #507cf2;
        }

        h4, ul {
            color: #343c50;
        }
    }

    @media only screen and (max-width: 500px) {
        padding: 30px 20px;
    }
`;

const ImgBox = styled.div`
    width: 300px;
    min-width: 300px;
    height: 235px;
    border-radius: 25px;
    overflow: hidden;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    @media only screen and (max-width: 765px) {
        min-width: 220px;
        width: 220px;
    }

    @media only screen and (max-width: 500px) {
        display: none;
    }
`;

const ContentsBox = styled.div`
    h2 {
        font-size: 26px;
        font-weight: 600;
        color: #595f6f;
        margin-bottom: 9px;
    }

    h4 {
        font-size: 16px;
        color: #7e838f;
        margin-bottom: 36px;
    }

    ul {
        font-size: 16px;
        color: #7e838f;
        padding-left: 20px;
    }

    li {
        list-style-type: square;
    }

    @media only screen and (max-width: 500px) {
        h2 {
            font-size: 22px;
        }
    }
`;

export default ({ data }) => (
    <Page title="Areas">
        <div className="shortInner">
            <PageTitle
                title="Research Areas"
                subTitle={data && data.introData[4]["Contents"]}
            ></PageTitle>
            <Container>
                {data &&
                    data.areasData.map((area, idx) => {
                        const params = encodeURI(area.Title);
                        return (
                            <Link
                                to={`/research/projects?page=1&category=${params}`}
                                key={area._id}
                            >
                                <Card>
                                    <ImgBox>
                                        <img src={area.Image} />
                                    </ImgBox>
                                    <ContentsBox>
                                        <h2>{area.Title}</h2>
                                        <h4>{area.Summary}</h4>
                                        <ul>
                                            {area.Contents.map((contents) => (
                                                <li key={contents}>
                                                    {contents}
                                                </li>
                                            ))}
                                        </ul>
                                    </ContentsBox>
                                </Card>
                            </Link>
                        );
                    })}
            </Container>
        </div>
    </Page>
);
