/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const PageController = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 80px;
`;

const PageBtn = styled.button`
    margin: 0;
    padding: 0;
    outline: none;
    width: 35px;
    height: 35px;
    background-color: #fff;
    cursor: pointer;
    border: none;
    font-size: 17px;
    color: ${({ current }) => (current ? "#507cf2" : "#7e838f")};
    font-weight: ${({ current }) => (current ? "600" : "initial")};
    text-decoration: ${({ current }) => (current ? "underline" : "none")};

    &.arrow {
        border: 1px solid #c0c0c0;
        padding-top: 4px;
        margin: 0 2px;

        &:hover span {
            color: #507cf2;
        }
    }
    & span {
        font-size: 26px;
        color: #7e838f;
    }

    &:hover {
        color: #507cf2;
    }

    @media only screen and (max-width: 500px) {
        font-size: 16px;
        width: 30px;
        height: 30px;

        &.arrow {
            padding-top: 2px;
        }
        & span {
            font-size: 24px;
        }
    }
`;

export default ({
    clickFirstBtn,
    clickLastBtn,
    clickNextBtn,
    clickPageNum,
    clickPrevBtn,
    filteredData,
    currentPage,
    pageList,
}) => {
    return (
    <PageController>
        <PageBtn
            className="arrow"
            onClick={() => {
                clickFirstBtn(filteredData);
            }}
        >
            <span className="material-icons-outlined">first_page</span>
        </PageBtn>
        <PageBtn
            className="arrow"
            style={{ marginRight: "15px" }}
            onClick={() => {
                clickPrevBtn(filteredData, currentPage);
            }}
        >
            <span className="material-icons-outlined">chevron_left</span>
        </PageBtn>

        {pageList &&
            pageList.map((page, idx) => (
                <PageBtn
                    key={idx}
                    current={page === Number(currentPage)}
                    onClick={() => {
                        clickPageNum(filteredData, page);
                    }}
                >
                    {page}
                </PageBtn>
            ))}

        <PageBtn
            className="arrow"
            style={{ marginLeft: "15px" }}
            onClick={() => {
                clickNextBtn(filteredData, currentPage);
            }}
        >
            <span className="material-icons-outlined">chevron_right</span>
        </PageBtn>
        <PageBtn
            className="arrow"
            onClick={() => {
                clickLastBtn(filteredData);
            }}
        >
            <span className="material-icons-outlined">last_page</span>
        </PageBtn>
    </PageController>
)};
