import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`;

export const CardContainer = styled.div`
    margin-left: 83px;
    width: 100%;
    max-width: 800px;
    overflow: auto;
    height: 1100px;
    padding-right: 20px;
    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bcbec4;
        border-radius: 10px;
        
        &:hover {
            background-color: #abadb3;
        }
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    @media only screen and (max-width: 865px) {
        margin-left: 0px;
        padding-right: 0px;
        height: initial;
    }
`;