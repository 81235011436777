/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { usePaging } from "../../../utils";
import Presenter from "./news.presenter";
import { isoftlab } from "../../../api";
import { withRouter } from "react-router-dom";

const MAX_VIEW_PAGE = 5;
const MAX_VIEW_DATA = 5;

const searchOption = {
    search: ["Title", "content_data", "Date"],
};

export default withRouter((props) => {
    return (
        <Presenter
            {...usePaging({
                dataFn: isoftlab.getNews,
                maxViewPage: MAX_VIEW_PAGE,
                maxViewData: MAX_VIEW_DATA,
                searchOption,
                urlProps: props,
            })}
        />
    );
});
