import Presenter from "./alumni.presenter";
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { isoftlab } from "../../../api";

export default withRouter((props) => {
    const useStudent = () => {
        const [alumniData, setAlumniData] = useState(null);
        const [divisionData, setDivisionData] = useState({});
        const [currentId, setCurrentId] = useState(0);
        const profileRef = useRef();

        const clickCard = (event, id, email) => {
            setCurrentId(id);

            const newUrl = `${props.history.location.pathname}?id=${btoa(email)}`;
            props.history.push(newUrl);

            if (profileRef.current) {
                if (!profileRef.current.classList.contains("mobile-show")) {
                    profileRef.current.classList.add("mobile-show");
                }
            }
        }

        const profileClose = () => {
            if (profileRef.current) {
                if (profileRef.current.classList.contains("mobile-show")) {
                    profileRef.current.classList.remove("mobile-show");
                }
            }
        }

        const init = async () => {
            const { data } = await isoftlab.getAlumni();
            const alumniMap = {};
            const divisionData = {};

            data.forEach((alumni, idx) => {
                if (idx === 0) {
                    setCurrentId(alumni._id);
                    const currentPath = document.querySelector(".currentPath");
                    if (currentPath) {
                        currentPath.addEventListener("click", () => setCurrentId(alumni._id));
                    }
                }

                alumniMap[alumni._id] = alumni;

                if (!divisionData[alumni.CurrentState]) {
                    divisionData[alumni.CurrentState] = [alumni];
                } else {
                    divisionData[alumni.CurrentState].push(alumni);
                }
            });

            setAlumniData(alumniMap);
            setDivisionData(divisionData);
        }

        useEffect(() => {
            init();
        }, []);

        return {
            alumniData,
            divisionData,
            currentId,
            profileRef,
            clickCard,
            profileClose
        }
    }

    return <Presenter {...useStudent()} />
})
