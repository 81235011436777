import React from "react";
import styled from "styled-components";

const Container = styled.div`
    &:not(:last-child) {
        margin-bottom: 80px;
    }
`;

const Title = styled.h2`
    font-size: 32px;
    color: #595f6f;
    font-weight: 500;
    margin-bottom: 15px;
`;

const CardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 35px;

    @media only screen and (max-width: 1060px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Card = styled.div`
    border: 1px solid #bcbec4;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;

    &:not(.current):hover {
        border: 1px solid #507cf2;
        box-shadow: 0 2px 15px 3px rgba(0, 0, 0, 0.1);

        & .cardTop * {
            color: ${({current}) => current ? "#ffffff" : "#507cf2"};
        }

        & .seemore {
            text-decoration: underline;
        }

        & .cardInfo > .label {
            color: #507cf2;
        }
    }

    @media only screen and (min-width: 865px) {
        &.current {
            background-color: #507cf2;
            border: 1px solid #507cf2;

            & .cardTop * {
                color: #ffffff;
            }

            & .cardInfo span {
                color: #e8e9eb;
            }

            & .cardInfo span.label {
                color: #b9c9f6;
            }

            & .seemore {
                display: none;
            }
        }
    }
`;

const CardTop = styled.div`
    display: flex;
    justify-content: space-between;

    & .seemore {
        font-size: 10px;
        color: #7e838f;
    }

    margin-bottom: 39px;
`;

const CardName = styled.div`
    color: #7e838f;
    font-weight: 600;

    & > h2 {
        font-size: 21px;
    }

    & > h3 {
        font-size: 16px;
    }
`;

const CardInfo = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 90px 1fr;

    & span {
        font-size: 14px;
        color: #7e838f;
        /* word-break: break-all; */
        white-space:nowrap;
    }

    & > .label {
        color: #769af2;
    }
`;

export default ({ title, data, currentId, clickCard, isStudent }) => (
    <Container>
        <Title>{title}</Title>
        <CardGrid>
            {data && data.map((stu, idx) => (
                <Card key={stu._id} className={ currentId === stu._id ? "current" : "" } onClick={(event,) => clickCard(event, stu._id, stu.Email)}>
                    <CardTop className="cardTop">
                        <CardName>
                            <h2>{stu.KorName}</h2>
                            <h3>{ stu.EngName }</h3>
                        </CardName>
                        <span className="seemore">See more</span>
                    </CardTop>
                    <CardInfo className="cardInfo">
                        <span className="label">Admission</span>
                        <span>{ stu.Admission }</span>
                        <span className="label">E-mail</span>
                        <span>{stu.Email}</span>
                        {!isStudent && stu.Career && (
                            <>
                                <span className="label">Current</span>
                                <div>
                                {stu.Career && stu.Career.map((career, i) => (
                                    <span key={`${career._id + i}`} style={{display: "block"}}>{career.temp}</span>
                                ))}
                                </div>
                            </>
                        )}
                    </CardInfo>
                </Card>
            ))}
        </CardGrid>
    </Container>
)