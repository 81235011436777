/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../../Components/Page";
import Post from "../../../../Components/NewsPost";

export default ({ news, goBack, history, backIsHome }) => (
    <Page>
        <div className="inner">
            {news &&
                <Post
                    data={news}
                    isNews={true}
                    goBack={goBack}
                    history={history}
                    backIsHome={backIsHome}
                />}
        </div>
    </Page>
);
