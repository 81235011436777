/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import Profile from "../../../Components/Profile";
import StudentCard from "../../../Components/StudentCard";
import {Container, CardContainer} from "../../../Components/Student-Alumnni";

import styled from "styled-components";


export default ({
    currentId,
    studentData,
    divisionData,
    profileRef,
    clickCard,
    profileClose
}) => {
    return (
        <Page title="Students">
            <div className="inner">
                {studentData && (
                    <Container>
                        <Profile profileRef={profileRef} className="profile" profile={studentData[currentId]} isStudent={true} profileClose={profileClose}></Profile>
                        <CardContainer>
                            {divisionData["Ph.D Student"] && (
                                <StudentCard
                                    title="Ph.D Students"
                                    data={divisionData["Ph.D Student"]}
                                    currentId={currentId}
                                    clickCard={clickCard}
                                    isStudent={true}
                                />
                            )}
                            {divisionData["M.S. Student"] && (
                                <StudentCard
                                    title="M.S. Students"
                                    data={divisionData["M.S. Student"]}
                                    currentId={currentId}
                                    clickCard={clickCard}
                                    isStudent={true}
                                />
                            )}
                            {divisionData["Intern"] && (
                                <StudentCard
                                    title="Intern Students"
                                    data={divisionData["Intern"]}
                                    currentId={currentId}
                                    clickCard={clickCard}
                                    isStudent={true}
                                />
                            )}
                        </CardContainer>
                    </Container>
                )}
            </div>
        </Page>
    )
};
