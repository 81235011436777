/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import "github-markdown-css";
import { Markdown } from "../utils";

const Date = styled.div`
    color: #7e838f;
    margin-bottom: 10px;
`;

const Title = styled.div`
    padding-bottom: 14px;
    font-size: 23px;
    font-weight: 600;
    color: #343c50;
`;

const Content = styled.div`
    border-top: 1px solid #595f6f;
    border-bottom: 1px solid #595f6f;
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    & p {
        width: 100%;
        max-width: 1000px;
        font-size: 17px;
    }

    & p:not(:last-child) {
        padding-bottom: 40px;
    }

    & > *:last-child {
        margin-bottom: 60px;
    }
`;

const BackBtnBox = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 60px;
`;

const BackBtn = styled.button`
    background-color: #507cf2;
    border: 0;
    outline: 0;
    color: #fff;
    width: 120px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    cursor: pointer;
`;

const File = styled.div`
    width: 100%;
    padding: 14px 0;
    border-top: 1px dashed #e8e9eb;
    margin-left: 16px;
    margin-bottom: 0 !important;

    & a {
        background-color: #e8e9eb;
        border: 1px solid #bcbec4;
        padding: 5px 8px;
        display: inline-block;
    }

    & div:not(:last-child) {
        margin-bottom: 15px;
    }
`;

const MarkdownBox = styled.div`
    margin: 60px 0;
    padding: 0 8%;

    p, li, a, span {
        font-family: "Poppins", "Noto Sans KR", sans-serif;
        font-size: 16px;
    }
`;

const ImageBox = styled.div`
    padding: 0 8%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        display: block;
        width: 100%;
        max-width: 900px;
        margin-top: 39px;
    }
`;

export default ({ data, isNews, goBack, history, backIsHome }) => (
    <>
        <Date>{data.Date}</Date>
        <Title>{data.Title}</Title>
        <Content>
            {data["image_data"] && data["image_data"].length > 0 && (
                <ImageBox>
                    {data["image_data"].map((url) => (
                        <img key={url} src={url} />
                    ))}
                </ImageBox>
            )}
            <MarkdownBox>
                {data["content_data"] && (
                    <Markdown text={data["content_data"]} />
                )}
            </MarkdownBox>
            {data.Attachment && data.Attachment.length > 0 && (
                <File>
                    {data.Attachment.map((attachment) => (
                        <div key={attachment.url}>
                            <a href={attachment.url} target="_blank">
                                {attachment["file_name"]}
                            </a>
                        </div>
                    ))}
                </File>
            )}
        </Content>
        <BackBtnBox>
            <BackBtn
                onClick={() => {
                    backIsHome || backIsHome === null ? history.push("/board/news?page=1") : goBack()
            }}>목록</BackBtn>
        </BackBtnBox>
    </>
);
