import { useState, useEffect, useRef } from "react";

export const Markdown = ({ text }) => {
    const hljs = require("highlight.js");
    const emoji = require("markdown-it-emoji");
    const highlights = require("markdown-it-highlightjs");
    const sub = require("markdown-it-sub");
    const sup = require("markdown-it-sup");
    const ins = require("markdown-it-ins");
    const mark = require("markdown-it-mark");
    const footnote = require("markdown-it-footnote");
    const deflist = require("markdown-it-deflist");
    const abbr = require("markdown-it-abbr");
    const containers = require("markdown-it-container");

    const md = require("markdown-it")({
        html: true,
        xhtmlOut: true,
        breaks: true,
        langPrefix: "language-",
        linkify: true,
        typographer: true,
        quotes: "“”‘’",
        highlight: function (str, lang) {
            if (lang && hljs.getLanguage(lang)) {
                try {
                    return (
                        '<pre class="hljs"><code>' +
                        hljs.highlight(lang, str, true).value +
                        "</code></pre>"
                    );
                } catch (__) {}
            }

            return (
                '<pre class="hljs"><code>' +
                md.utils.escapeHtml(str) +
                "</code></pre>"
            );
        },
    })
        .use(highlights, { inline: true })
        .use(emoji)
        .use(sub)
        .use(sup)
        .use(ins)
        .use(mark)
        .use(footnote)
        .use(deflist)
        .use(abbr)
        .use(containers);

    // text = text.replace(/\n/gi, "<br/>");

    return (
        <div
            className="markdown-body"
            dangerouslySetInnerHTML={{ __html: md.render(text) }}
        ></div>
    );
};

export const paramsParsing = (query, params) => {
    if (query) {
        query = query.split("&");
        query.forEach((param) => {
            param = decodeURI(param);
            param = param.split("=");
            try {
                const key = decodeURIComponent(param[0]);
                const value = decodeURIComponent(param[1]);

                if (params[key] === null) {
                    params[key] = value;
                }
            } catch {}
        });
    }

    return params;
};

export const getUrl = (url, params) => {
    let count = 0;
    for (let key in params) {
        const value = params[key];
        if (value) {
            url += count !== 0 ? "&" : "?";
            url += `${key}=${value}`;
        }
        count++;
    }

    return url.replace(/ /gi, "%20");
};

const scrollBoxTop = () => {
    const scrollBoxs = document.querySelectorAll(".scrollBox");

    scrollBoxs.forEach((scrollBox) => {
        scrollBox.scrollTo(0, 0);
    });
};

export const usePaging = ({
    dataFn,
    maxViewPage,
    maxViewData,
    searchOption,
    urlProps,
    isItemView,
}) => {
    const [baseData, setBaseData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [viewData, setViewData] = useState(null);
    const [pageList, setPageList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [keyword, setKeyword] = useState(null);
    const [category, setCategory] = useState(null);
    const [fixedData, setFixedData] = useState(null);
    const [categoryMap, setCategoryMap] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const searchInput = useRef();

    const init = async () => {
        // url 세팅
        const query = urlProps.location.search.substr(1);
        const params = paramsParsing(query, {
            page: null,
            category: null,
            keyword: null,
        });
        const url = getUrl(urlProps.location.pathname, params);
        const {
            history: {
                location: { pathname, search },
            },
        } = urlProps;

        if (!search) {
            urlProps.history.push(`${pathname}?page=1`);
        }
        if (pathname + search !== url) {
            urlProps.history.push(url);
        }

        const initKeyword = params.keyword ? decodeURI(params.keyword) : null;
        let initCategory = params.category ? decodeURI(params.category) : null;

        setCurrentPage(params.page ?? 1);
        setKeyword(initKeyword);
        // setCategory(initCategory);

        // 데이터 로드

        const { data } = await dataFn();
        let filterData = data;
        let baseData = data;
        if (data && data.fixed) {
            baseData = data.unfixed;
            filterData = data.unfixed;
            setFixedData(data.fixed);
        } else {
            if (data.length > 0 && data[0].Category) {
                const categoryMap = getCategoryMap(data);
                if (!categoryMap[initCategory]) {
                    initCategory = null;
                }
                setCategoryMap(categoryMap);
            }
        }

        setCategory(initCategory);

        if (params.keyword || params.category) {
            filterData = getFilteredData(baseData, initKeyword, initCategory);
        }

        setBaseData(baseData);
        setFilteredData(filterData);
        setPage(filterData, params.page ?? 1, 1);
    };

    const getCategoryMap = (data) => {
        const categoryMap = {};

        categoryMap["All Projects"] = data.length;
        data.forEach((d) => {
            d.Category.forEach((c) => {
                if (categoryMap[c]) {
                    categoryMap[c]++;
                } else {
                    categoryMap[c] = 1;
                }
            });
        });

        return categoryMap;
    };

    const getFilteredData = (baseData, keyword, currentCategory) => {
        const checkKeyword = (target, keyword) => {
            if (!target) return false;
            if (!keyword) return true;
            keyword = keyword.toLowerCase();

            if (typeof target === "string") {
                target = target.toLowerCase();

                return target.includes(keyword);
            } else if (typeof target === "object") {
                target = target.map((item) => item.toLowerCase());
                let ok = false;

                for (let i = 0; i < target.length; i++) {
                    if (ok) break;
                    const checkTarget = target[i].toLowerCase();
                    ok = checkTarget.includes(keyword);
                }

                return ok;
            }
        };

        const checkCategory = (target, category) => {
            if (typeof target === "string") {
                return target === category;
            } else if (typeof target === "object") {
                let ok = false;

                for (let i = 0; i < target.length; i++) {
                    if (ok) break;
                    ok = target[i] === category;
                }
                return ok;
            }
        };

        const { search, category } = searchOption;

        return baseData.filter((data) => {
            let categoryOk = category && currentCategory ? false : true;
            let ok = false;

            if (category && currentCategory) {
                categoryOk = checkCategory(data[category], currentCategory);
            }

            if (!categoryOk) {
                return false;
            }

            for (let i = 0; i < search.length; i++) {
                if (ok) break;
                ok = checkKeyword(data[search[i]], keyword);
            }

            return ok;
        });
    };

    const getMaxPage = (data) => {
        return Math.ceil(data.length / maxViewData);
    };

    // type = 0이면 pageList 갱신안함
    const setPage = (baseData, pageNum, type = 0) => {
        const maxPage = getMaxPage(baseData);
        const startIdx = (pageNum - 1) * maxViewData;
        const newData = [];

        if (type === 1) {
            const newPageList = [];
            const startPageNum =
                Math.floor((pageNum - 1) / maxViewPage) * maxViewPage + 1;

            for (let i = startPageNum; i < startPageNum + maxViewPage; i++) {
                if (i <= maxPage) {
                    newPageList.push(i);
                } else {
                    break;
                }
            }

            setPageList(newPageList.length !== 0 ? newPageList : [1]);
        }

        for (let i = startIdx; i < startIdx + maxViewData; i++) {
            if (i < baseData.length) {
                newData.push(baseData[i]);
            } else {
                break;
            }
        }

        setViewData(newData);
        if (isItemView) {
            setCurrentItem(newData[0]);
        }
        setCurrentPage(pageNum);
    };

    const handleSearch = (event, currentCategory) => {
        const {
            target: { value: keyword },
        } = event;

        const resultNewsList = getFilteredData(
            baseData,
            keyword,
            currentCategory
        );
        setFilteredData(resultNewsList);
        setPage(resultNewsList, 1, 1);
        setKeyword(keyword);
    };

    const setUrl = ({ page, clickCategory }) => {
        const {
            history: {
                location: { pathname },
            },
        } = urlProps;
        let newUrl = `${pathname}?page=${page}`;

        if (keyword) {
            newUrl += `&keyword=${keyword}`;
        }
        if (clickCategory && clickCategory !== "all") {
            newUrl += `&category=${clickCategory}`;
        } else if (category && !clickCategory) {
            newUrl += `&category=${category}`;
        }
        urlProps.history.push(newUrl);
    };

    const clickCategory = (isAll, inputCategory, keyword) => {
        const currentCategory = isAll ? null : inputCategory;

        setCategory(currentCategory);
        const resultNewsList = getFilteredData(
            baseData,
            keyword,
            currentCategory
        );
        setFilteredData(resultNewsList);
        setPage(resultNewsList, 1, 1);
        setUrl({
            page: 1,
            clickCategory: currentCategory ?? "all",
        });
    };

    const clickPageNum = (baseData, pageNum) => {
        if (baseData.length === 0) return;

        setPage(baseData, pageNum);
        setUrl({
            page: pageNum,
        });
        window.scrollTo(0, 0);
        scrollBoxTop();
    };

    const clickNextBtn = (baseData, pageNum) => {
        if (baseData.length === 0) return;
        const nextStartNum =
            (Math.floor((pageNum - 1) / maxViewPage) + 1) * maxViewPage + 1;
        const maxPage = getMaxPage(baseData);
        if (nextStartNum <= maxPage) {
            setPage(baseData, nextStartNum, 1);
            setUrl({
                page: nextStartNum,
            });
        } else {
            setPage(baseData, maxPage);
            setUrl({
                page: maxPage,
            });
        }
        window.scrollTo(0, 0);
        scrollBoxTop();
    };

    const clickPrevBtn = (baseData, pageNum) => {
        if (baseData.length === 0) return;

        const prevLastNum =
            Math.floor((pageNum - 1) / maxViewPage) * maxViewPage;

        if (prevLastNum > 0) {
            setPage(baseData, prevLastNum, 1);
            setUrl({
                page: prevLastNum,
            });
        } else {
            setPage(baseData, 1);
            setUrl({
                page: 1,
            });
        }
        window.scrollTo(0, 0);
        scrollBoxTop();
    };

    const clickLastBtn = (baseData) => {
        if (baseData.length === 0) return;

        const maxPage = getMaxPage(baseData);
        setPage(baseData, maxPage, 1);
        setUrl({
            page: maxPage,
        });
        window.scrollTo(0, 0);
        scrollBoxTop();
    };

    const clickFirstBtn = (baseData) => {
        if (baseData.length === 0) return;

        setPage(baseData, 1, 1);
        setUrl({
            page: 1,
        });
        window.scrollTo(0, 0);
        scrollBoxTop();
    };

    const clickCard = () => {
        setUrl({
            page: currentPage,
        });
    };

    const clickItem = (item) => {
        setCurrentItem(item);
        const detail = document.querySelectorAll(".scrollBox")[1];

        detail.scrollTo(0, 0);
    };

    const inputFocusOut = () => {
        if (searchInput.current) {
            searchInput.current.blur();
        }
    };

    const setInit = () => {
        setFilteredData(baseData);
        setPage(baseData, 1, 1);
        setKeyword(null);
        setCategory(null);
    };

    useEffect(() => {
        if (!baseData) {
            init();
        }

        if (baseData) {
            const currentPath = document.querySelectorAll(".currentPath");

            currentPath.forEach((path) => {
                path.addEventListener("click", setInit);
            });
        }

        window.scrollTo(0, 0);
        // window.addEventListener("scroll", inputFocusOut);

        window.onpopstate = () => {
            const query = urlProps.history.location.search.substr(1);
            const params = paramsParsing(query, {
                page: null,
                category: null,
                keyword: null,
            });

            setCurrentPage(params.page ?? 1);
            setKeyword(params.keyword);
            setCategory(params.category);

            let filterData = baseData;
            if (params.keyword || params.category) {
                filterData = getFilteredData(
                    baseData,
                    params.keyword,
                    params.category
                );
            }

            setFilteredData(filterData);
            setPage(filterData, params.page ?? 1, 1);
            // window.scrollTo(0, 0);
        };

        return () => {
            window.removeEventListener("scroll", inputFocusOut);
        };
    }, [baseData]);

    return {
        filteredData,
        viewData,
        currentPage,
        pageList,
        keyword,
        searchInput,
        category,
        urlProps,
        fixedData,
        categoryMap,
        currentItem,
        clickPageNum,
        clickNextBtn,
        clickPrevBtn,
        clickLastBtn,
        clickFirstBtn,
        clickCategory,
        clickCard,
        clickItem,
        handleSearch,
    };
};
