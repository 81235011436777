// 헤더에 추가될 메뉴는 여기서 수정
// 서브메뉴가 없는 경우, object에 subMenuList를 추가하지 않으면 됩니다.
// 각각의 Link는 src/Components/Router.js 파일에서 라우팅 한 링크를 입력하면 됩니다.

const menuList = [
    {
        topMenu: "Members",
        topMenuLink: "/members",
        root: "/members",
        subMenuList: [
            {
                subMenu: "Professor",
                subMenuLink: "/members/professor",
            },
            {
                subMenu: "Students",
                subMenuLink: "/members/students",
            },
            {
                subMenu: "Alumni",
                subMenuLink: "/members/alumni",
            },
        ],
    },
    {
        topMenu: "Research",
        topMenuLink: "/research/research_areas",
        root: "/research",
        subMenuList: [
            {
                subMenu: "Research Areas",
                subMenuLink: "/research/research_areas",
            },
            {
                subMenu: "Projects",
                subMenuLink: "/research/projects?page=1",
            },
            {
                subMenu: "Seminar",
                subMenuLink: "/research/seminar?page=1",
            },
        ],
    },
    {
        topMenu: "Publications",
        topMenuLink: "/publications/selected_paper",
        root: "/publications",
        subMenuList: [
            {
                subMenu: "Selected Paper",
                subMenuLink: "/publications/selected_paper",
            },
            {
                subMenu: "Publications",
                subMenuLink: "/publications/publications?page=1",
            },
        ],
    },
    {
        topMenu: "Board",
        topMenuLink: "/board/news",
        root: "/board",
        subMenuList: [
            {
                subMenu: "News",
                subMenuLink: "/board/news?page=1",
            },
            {
                subMenu: "Album",
                subMenuLink: "/board/album?page=1",
            },
        ],
    },
    {
        topMenu: "Live Demo",
        topMenuLink: "/livedemo",
        root: "/livedemo",
    },
    {
        topMenu: "Resource",
        topMenuLink: "/resource",
        root: "/resource",
    },
    {
        topMenu: "Contact",
        topMenuLink: "/contact/apply",
        root: "/contact",
        subMenuList: [
            {
                subMenu: "Apply",
                subMenuLink: "/contact/apply",
            },
            {
                subMenu: "FAQ",
                subMenuLink: "/contact/FAQ",
            },
        ],
    },
];

export default menuList;
