/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import PageController from "../../../Components/PageController";
import SearchBox from "../../../Components/SearchBox";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ProjectCard from "../../../Components/ProjectCard";

const Container = styled.div`
    /* margin-top: 91px; */
    border-top: 1px solid #595f6f;
    color: #595f6f;

    @media only screen and (max-width: 500px) {
        margin-top: 20px;
    }
`;

const ListViewContainer = styled.div`
    margin-top: 18px;
    margin-bottom: 10px;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    
    button {
        width: 46px;
        height: 46px;
        border: 1px solid #bcbec4;
        color: #7e838f;
        background-color: #ffffff;
        border-radius: 7px;
        cursor: pointer;

        span {
            position: relative;
            top: 2px;
            font-size: 30px;
        }
    }

    button:hover, button.current {
        color: #507cf2;
        border: 1px solid #507cf2;
    }
`;

const CategoryContainer = styled.div`
    & h2 {
        font-size: 20px;
        font-weight: 700;
        color: #595f6f;
        margin-top: 18px;
        margin-bottom: 17px;
    }
`;

const CategoryBox = styled.div`
    background-color: #e6ecfb;
    border-radius: 10px;
    padding: 25px;
    padding-right: 9px;

    & > div {
        height: 200px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #bcbec4;
            border-radius: 10px;
            
            &:hover {
                background-color: #abadb3;
            }
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }
`;

const CategoryGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    padding-right: 8px;
`;

const CategoryItem = styled.div`
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #bcbec4;

    & > h4 {
        font-weight: 600;
    }

    & span {
        font-size: 13px;
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span {
            color: #595f6f;
        }

        & > span:last-child {
            font-size: 17px;
            margin-top: 4px;
        }
    }

    &:hover {
        color: #507cf2;
        border: 1px solid #507cf2;

        & > div {
            & > span {
                color: #507cf2;
            }
        }
    }

    &.current {
        background-color: #507cf2;
        color: #fff;

        & > div {
            & > span {
                color: #ffffff;
            }
        }
    }
`;

const TotalItem = styled.div`
    margin-top: 45px;
    padding-bottom: 15px;
    font-size: 19px;
    color: #595f6f;
    & > span:nth-child(2) {
        color: #507cf2;
        font-size: 20px;
        font-weight: 600;
    }

    & > span:nth-child(3) {
        margin-left: 10px;
        font-size: 14px;

        display: none;

        &.show {
            display: inline;
        }
    }

    @media only screen and (max-width: 800px) {
        font-size: 17px;

        & > span:nth-child(2) {
            font-size: 18px;
        }

        & > span:nth-child(3) {
            font-size: 13px;
            display: none;

            &.show {
                display: inline;
            }
        }
    }
`;

const ItemGrid = styled.div`
    display: grid;
    gap: 40px;
    grid-template-columns: ${({listView}) => listView ? "1fr" : "repeat(auto-fill, minmax(300px, 1fr))"};
    & > a > div {
        height: 100%;
    }
`;

export default ({
    categoryMap,
    categoryOption,
    total,
    filteredData,
    viewData,
    currentPage,
    pageList,
    keyword,
    category,
    urlProps,
    searchInput,
    clickPageNum,
    clickNextBtn,
    clickPrevBtn,
    clickLastBtn,
    clickFirstBtn,
    clickCategory,
    clickCard,
    handleSearch,
    listView,
    setListView
}) => {
    const clickProjectCategory = (event, isAll, inputCategory, keyword) => {
        let { target } = event;

        while (!target.classList.contains("category")) {
            target = target.parentNode;
        }

        const cnt = target.parentNode.querySelector(".current");

        if (cnt) {
            cnt.classList.remove("current");
        }
        target.classList.add("current");

        clickCategory(isAll, inputCategory, keyword);
    };

    const categoryKey = categoryMap ? Object.keys(categoryMap) : null;
    
    return (
        <Page title="Album">
            <div className="inner">
                <SearchBox
                    handleSearch={(event) => {
                        handleSearch(event, category);
                    }}
                    searchInput={searchInput}
                    initKeyword={keyword}
                />
                <ListViewContainer>
                    <button className={listView ? "current" : ""} onClick={() => setListView(true)}>
                        <span className="material-icons-outlined">
                            format_list_bulleted
                        </span>
                    </button>
                    <button className={listView ? "" : "current"} onClick={() => setListView(false)}>
                        <span className="material-icons-outlined">
                            grid_view
                        </span>
                    </button>
                </ListViewContainer>
                <Container>
                    <CategoryContainer>
                        <h2>Category</h2>
                        <CategoryBox>
                            <div>
                                <CategoryGrid>
                                    {categoryMap &&
                                        categoryKey &&
                                        categoryKey.map((item, idx) => (
                                            <CategoryItem
                                                className={`category${
                                                    category === item ||
                                                    (category === null &&
                                                        item === "All Projects")
                                                        ? " current"
                                                        : ""
                                                }`}
                                                key={idx}
                                                onClick={(event) => {
                                                    clickProjectCategory(
                                                        event,
                                                        item === "All Projects",
                                                        item,
                                                        keyword
                                                    );
                                                }}
                                            >
                                                <h4>{item}</h4>
                                                <div>
                                                    <span>
                                                        {categoryMap[item]}{" "}
                                                        Projects
                                                    </span>
                                                    <span className="material-icons-outlined">
                                                        more_horiz
                                                    </span>
                                                </div>
                                            </CategoryItem>
                                        ))}
                                </CategoryGrid>
                            </div>
                        </CategoryBox>
                    </CategoryContainer>
                    <TotalItem>
                        <span>Number of entries: </span>
                        <span>{filteredData && filteredData.length}</span>
                        <span
                            className={
                                (keyword && keyword !== "") || category
                                    ? "show"
                                    : ""
                            }
                        >
                            (Filtered)
                        </span>
                    </TotalItem>
                    <ItemGrid listView={listView}>
                        {viewData &&
                            viewData.map((item, idx) => (
                                <ProjectCard
                                    item={item}
                                    key={item._id}
                                    page={currentPage}
                                    category={category}
                                    keyword={keyword}
                                    clickCard={clickCard}
                                    listView={listView}
                                />
                            ))}
                    </ItemGrid>
                </Container>

                <PageController
                    clickFirstBtn={clickFirstBtn}
                    clickLastBtn={clickLastBtn}
                    clickNextBtn={clickNextBtn}
                    clickPageNum={clickPageNum}
                    clickPrevBtn={clickPrevBtn}
                    filteredData={filteredData}
                    currentPage={currentPage}
                    pageList={pageList}
                />
            </div>
        </Page>
    );
};
