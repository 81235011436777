/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import Page from "../../../Components/Page";

import "github-markdown-css";

import { Markdown } from "../../../utils";

const Container = styled.div`
    display: flex;
    font-size: 15px;
    align-items: flex-start;

    @media only screen and (max-width: 865px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ProfileBox = styled.div`
    width: 100%;
    max-width: 300px;
    margin-right: 150px;

    @media only screen and (max-width: 1060px) {
        margin-right: 80px;
        /* max-width: 250px; */
    }
    
    @media only screen and (max-width: 865px) {
        max-width: 500px;
        width: 100%;
        margin-right: 0px;
        margin-bottom: 70px;
    }
`;

const ProfileImg = styled.div`
    width: 100%;

    display: flex;
    /* justify-content: center; */
    
    & > img {
        width: 100%;
        height: 100%;
        max-height: 350px;
        display: block;
        /* max-width: 300px; */
        object-fit: cover;
        object-position: center;
    }

    @media only screen and (max-width: 865px) {
        display:flex;
        justify-content: center;

        & > img {
            width: 320px;
            height: 320px;
        }
    }

    @media only screen and (max-width: 436px) {
        display:flex;
        justify-content: center;

        & > img {
            width: 250px;
            height: 250px;
        }
    }
`;

const ProfileName = styled.div`
    margin-top: 33px;
    margin-bottom: 49px;
    font-weight: 600;
    & > h2 {
        font-size: 26px;
    }

    & > h3 {
        font-size: 18px;
    }

    @media only screen and (max-width: 865px) {
        margin-top: 60px;
    }
`;

const Label = styled.div`
    color: #507cf2;
    font-weight: 600;
    display: flex;
    align-items: center;

    &.notBold {
        font-weight: 500;
    }

    span:first-child {
        font-size: 18px;
        margin-right: 7px;
    }
    img {
        margin-right: 7px;
        width: 17px;
    }
`;

const Profile = styled.div`
    margin-bottom: 26px;
`;

const ProfileInfo = styled.div`
    display: grid;
    grid-template-columns: 90px 1fr;
    margin-bottom: 33px;

    & > div {
    }
`;

const ProfileHashtag = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #7e838f;

    & > div > span:first-child {
        color: #507cf2;
        margin-right: 2px;
    }

    & > div:not(:last-child) {
        margin-right: 15px;
    }
`;

const Href = styled.a`
    word-break: break-all;
    &:hover {
        color: #769af2;
        text-decoration: underline;
    }

    cursor: pointer;
`;

const DetailBox = styled.div`
    width: 100%;

    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 70px;

    @media only screen and (max-width: 1060px) {
        display: flex;
        flex-direction: column;
        gap: 0;

        & > div:nth-child(odd) {
            margin-bottom: 20px;
        }

        & > div:nth-child(even):not(:last-child) {
            margin-bottom: 70px;
        }
    }

    @media only screen and (max-width: 865px) {
        & > div:nth-child(even):not(:last-child) {
            margin-bottom: 20px;
        }

        & > div:nth-child(odd) {
            margin-bottom: 70px;
        }
    }
`;

const DetailLabel = styled.div`
    color: #507cf2;
    font-weight: 600;
    font-size: 18px;
    text-align: right;

    @media only screen and (max-width: 1060px) {
        text-align: left;
    }
`;

const OneYearBox = styled.div`
    display: grid;
    grid-template-columns: 70px 1fr;
    color: #595f6f;

    & > div:nth-child(even) {
        font-weight: 600;
    }

    & > div:not(:last-child) {
        margin-bottom: 15px;
    }
`;

const TwoYearBox = styled.div`
    display: grid;
    grid-template-columns: 120px 1fr;
    color: #595f6f;
    & > div:nth-child(even) {
        font-weight: 600;
    }

    & > div:not(:last-child) {
        margin-bottom: 15px;
    }

    & ul {
        padding-left: 30px;

        & li {
            color: #7e838f;
            font-size: 14px;
            font-weight: 400;
            list-style-type: disc;
        }
    }
`;

const Subject = styled.div`
    font-size: 12px;
    color: #7e838f;
    font-weight: 500;
`;

const MarkdownBox = styled.div`
    width: 100%;
    grid-column: span 2;
    
    & > div:first-child {
        padding: 15px;
        padding-right: 5px;
        padding-bottom: 5px;
        border: 1px solid #b9c9f6;
        border-radius: 10px;

        & > div {
            width: 100%;
            height: 400px;
            overflow: auto;
            padding-right: 15px;
            padding-bottom: 15px;
    
            img {
                width: initial;
                max-width: 100%;
            }
    
            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                background-color: transparent;
            }
    
            &::-webkit-scrollbar-thumb {
                background-color: #7e838f;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
        }
    }

    & > div:last-child {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
`;

const ClipBoard = styled.input`
    opacity: 0;
    min-height: 0;
    position: absolute;
`;

const Seemore = styled.div`
    /* display: flex;
    justify-content: center; */
    grid-column: span 2;
    padding-top: 28px;
    width: 100%;

    span {
        color: #7e838f;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: right;

        &:hover {
            color: #507cf2;
            text-decoration: underline;
        }

        &.seemore {
            text-align: center;
        }
    }
`;

const AddComment = styled.a`
    border: 1px solid #7e838f;
    border-radius: 10px;
    color: #7e838f;
    display: flex;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;

    img {
        display: block;
        width: 16px;
    }
    
    img:nth-child(2) {
        display: none;
    }

    :hover {
        background-color: #595f6f;
        color: #fff;

        img:nth-child(1) {
            display: none;
        }
        img:nth-child(2) {
            display: block;
        }
    }
`;

const clickInfo = (event, text) => {
    const clipBoard = document.querySelector(".clipBoard");
    const successBox = document.querySelector(".successBox");

    clipBoard.classList.remove("hide");
    clipBoard.value = text;
    clipBoard.select();

    document.execCommand("copy");

    clipBoard.blur();

    successBox.style.transition = "none";
    successBox.style.opacity = "1";
    successBox.style.zIndex = "9999";
    setTimeout(() => {
        successBox.style.transition =
            "opacity 0s linear 1.5s, z-index 0s linear 1.5s";
        successBox.style.opacity = "0";
        successBox.style.zIndex = "-9999";
    }, 10);
}

export default ({ professor, info, overYears, clickSeemore }) => {
    const date = new Date();
    const year = date.getFullYear();

    return (
        <Page>
            <div className="inner">
                {professor && info && (
                    <Container>
                        <ProfileBox>
                            <ProfileImg>
                                <img src={info.Image} />
                            </ProfileImg>
                            <ProfileName>
                                <h2>{info.KorName}</h2>
                                <h3>{info.EngName}</h3>
                            </ProfileName>
                            <Profile>
                                <Label>{info.Rank}</Label>
                                <p>
                                    Computer Science and Engineering,
                                    <br />
                                    Chungnam National University
                                </p>
                            </Profile>
                            <ProfileInfo>
                                <Label className="notBold">
                                    <span className="material-icons">call</span>
                                    <span>Call</span>
                                </Label>
                                <div>
                                    <Href
                                        onClick={(event) =>
                                            clickInfo(event, info.LabNumber)
                                        }
                                    >
                                        Click to copy
                                    </Href>
                                </div>
                                <Label className="notBold">
                                    <span className="material-icons-outlined">
                                        email
                                    </span>
                                    <span>E-mail</span>
                                </Label>
                                <div>
                                    <Href
                                        onClick={(event) =>
                                            clickInfo(event, info.Email)
                                        }
                                    >
                                        Click to copy
                                    </Href>
                                </div>
                                <Label className="notBold">
                                    <img src="/img/google-scholar-svgrepo-com.svg" />
                                    <span>Scholar</span>
                                </Label>
                                <div>
                                    <Href
                                        onClick={(event) =>
                                            clickInfo(event, info.GoogleScholar)
                                        }
                                    >
                                        Click to copy
                                    </Href>
                                </div>
                                <Label className="notBold">
                                    <span className="material-icons-outlined">
                                        apartment
                                    </span>
                                    <span>Office</span>
                                </Label>
                                <div>
                                    <Href
                                        onClick={(event) =>
                                            clickInfo(event, info.LabAddress)
                                        }
                                    >
                                        Click to copy
                                    </Href>
                                </div>
                            </ProfileInfo>
                            <ProfileHashtag>
                                {professor.Intersets &&
                                    professor.Intersets.map((interset, idx) => (
                                        <div key={interset._id}>
                                            <span>#</span>
                                            <span>{interset.Name}</span>
                                        </div>
                                    ))}
                            </ProfileHashtag>
                        </ProfileBox>
                        <DetailBox>
                            <MarkdownBox>
                                <div>
                                    {info && <Markdown text={info.Markdown} />}
                                </div>
                                <div>
                                    <AddComment href={info.Markdown_Url} target="_blank">
                                        <img src="/img/notion-logo.svg" />
                                        <img src="/img/notion-logo-hover.svg" />
                                        <span>Click To Edit</span>
                                    </AddComment>
                                </div>
                            </MarkdownBox>
                            {professor.Education &&
                                professor.Education.length > 0 && (
                                    <>
                                        <DetailLabel>Education</DetailLabel>
                                        <OneYearBox>
                                            {professor.Education.map(
                                                (education, idx) => (
                                                    <React.Fragment
                                                        key={education._id}
                                                    >
                                                        <div>
                                                            {education.Date}
                                                        </div>
                                                        <div>
                                                            {education.Degree}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </OneYearBox>
                                    </>
                                )}

                            {professor.Employment &&
                                professor.Employment.length > 0 && (
                                    <>
                                        <DetailLabel>Employment</DetailLabel>
                                        <TwoYearBox>
                                            {professor.Employment.map(
                                                (employment, idx) => (
                                                    <React.Fragment
                                                        key={employment._id}
                                                    >
                                                        <div>
                                                            {employment.Date}
                                                        </div>
                                                        <div>
                                                            {employment.Company}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </TwoYearBox>
                                    </>
                                )}

                            {professor.Instructor &&
                                professor.Instructor.length > 0 && (
                                    <>
                                        <DetailLabel>Instructor</DetailLabel>
                                        <TwoYearBox>
                                            {professor.Instructor.map(
                                                (instructor, idx) => (
                                                    <React.Fragment
                                                        key={instructor._id}
                                                    >
                                                        <div>
                                                            {instructor.Date}
                                                        </div>
                                                        <div>
                                                            <div>
                                                                {
                                                                    instructor.Name
                                                                }
                                                            </div>
                                                            <ul>
                                                                {instructor.Contents.map(
                                                                    (
                                                                        contents
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                contents
                                                                            }
                                                                        >
                                                                            {
                                                                                contents
                                                                            }
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </TwoYearBox>
                                    </>
                                )}

                            {professor.Affiliations &&
                                professor.Affiliations.length > 0 && (
                                    <>
                                        <DetailLabel>Affiliations</DetailLabel>
                                        <TwoYearBox>
                                            {professor.Affiliations.map(
                                                (affiliation, idx) => {
                                                    if (affiliation.Name === "")
                                                        return "";
                                                    return (
                                                        <React.Fragment
                                                            key={
                                                                affiliation._id
                                                            }
                                                        >
                                                            <div>
                                                                {
                                                                    affiliation.Date
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    affiliation.Name
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }
                                            )}
                                        </TwoYearBox>
                                    </>
                                )}
                            {professor.Awards && professor.Awards.length > 0 && (
                                <>
                                    <DetailLabel>Honors & Awards</DetailLabel>
                                    <OneYearBox>
                                        {professor.Awards &&
                                            professor.Awards.map(
                                                (award, idx) => {
                                                    if (award.Name === "")
                                                        return "";
                                                    return (
                                                        <React.Fragment
                                                            key={award._id}
                                                        >
                                                            <div>
                                                                {award.Date}
                                                            </div>
                                                            <div>
                                                                {award.Name}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }
                                            )}
                                    </OneYearBox>
                                </>
                            )}
                            {professor.Activities &&
                                professor.Activities.length > 0 && (
                                    <>
                                        <DetailLabel>Activities</DetailLabel>
                                        <TwoYearBox>
                                            {professor.Activities.map(
                                                (activity, idx) => {
                                                    if (activity.Name === "")
                                                        return "";
                                                    return (
                                                        <React.Fragment
                                                            key={activity._id}
                                                        >
                                                            <div>
                                                                {activity.Date}
                                                            </div>
                                                            <div>
                                                                {activity.Name}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }
                                            )}
                                        </TwoYearBox>
                                    </>
                                )}
                            {professor.Teaching &&
                                professor.Teaching.length > 0 && (
                                    <>
                                        <DetailLabel>Teaching</DetailLabel>
                                        <TwoYearBox>
                                            {professor.Teaching.map(
                                                (teaching) => {
                                                    if (teaching.Name === "")
                                                        return "";
                                                    const teachingYear =
                                                        parseInt(
                                                            teaching.Date.split(
                                                                " "
                                                            )[0]
                                                        );

                                                    return (
                                                        <React.Fragment
                                                            key={teaching._id}
                                                        >
                                                            <div
                                                                className={
                                                                    year -
                                                                        teachingYear >=
                                                                    3
                                                                        ? "hide"
                                                                        : ""
                                                                }
                                                                {...(year -
                                                                    teachingYear >=
                                                                    3 && {
                                                                    ref: (el) =>
                                                                        overYears.current.push(
                                                                            el
                                                                        ),
                                                                })}
                                                            >
                                                                {teaching.Date}
                                                            </div>
                                                            <div
                                                                className={
                                                                    year -
                                                                        teachingYear >=
                                                                    3
                                                                        ? "hide"
                                                                        : ""
                                                                }
                                                                {...(year -
                                                                    teachingYear >=
                                                                    3 && {
                                                                    ref: (el) =>
                                                                        overYears.current.push(
                                                                            el
                                                                        ),
                                                                })}
                                                            >
                                                                {teaching.Name &&
                                                                    teaching.Name.map(
                                                                        (
                                                                            name,
                                                                            idx
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={`${name._id}${idx}`}
                                                                            >
                                                                                <span>
                                                                                    {
                                                                                        name
                                                                                    }
                                                                                </span>
                                                                                <br />
                                                                            </React.Fragment>
                                                                        )
                                                                    )}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }
                                            )}
                                            <Seemore>
                                                <span
                                                    className="seemore"
                                                    onClick={clickSeemore}
                                                >
                                                    See more
                                                </span>
                                            </Seemore>
                                        </TwoYearBox>
                                    </>
                                )}
                        </DetailBox>
                    </Container>
                )}
            </div>
        </Page>
    );
};
