/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";

const Main = styled.div`
    padding-top: 80px;
    min-height: 100vh;
`;

const NavBarContainer = styled.div`
    border-bottom: 1px solid #e8e9eb;
`;

const NavBar = styled.div`
    color: #bcbec4;
    display: flex;
    align-items: center;
    padding: 7px 0;

    & .navBarHome {
        position: relative;
        top: -1px;
        font-size: 24px;
        margin-right: 5px;
        display: block;
    }

    & .navBarArrow {
        position: relative;
        top: 2px;
        margin: 0 5px;
        font-size: 15px;
    }

    & > a:last-child {
        & > span:last-child {
            color: #769af2;
            font-weight: 600;
        }
    }

    @media only screen and (max-width: 850px) {
        font-size: 15px;
        & .navBarHome {
            font-size: 20px;
        }

        & .navBarArrow {
            font-size: 14px;
        }
    }
`;

const NavBarPath = styled.span``;

const Content = styled.div`
    padding: 100px 0px;

    @media only screen and (max-width: 865px) {
        padding-top: 30px;
    }
`;

// const GoToTop = styled.div`
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     z-index: -1000;

//     .inner {
//         height: 100%;
//         position: relative;
//     }

//     button {
//         position: absolute;
//         right: 20px;
//         bottom: 40px;
//         width: 70px;
//         height: 70px;
//         border-radius: 100%;
//         box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.1);
//         background-color: #fff;
//         color: #769af2;
//         padding: 0;
//         margin: 0;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         border: none;
//         outline: none;
//         cursor: pointer;
//         z-index: 10000;

//         &:hover {
//             background-color: #769af2;
//             color: #fff;
//         }

// span {
//     font-size: 50px;
//     font-weight: 900;
// }

// @media only screen and (max-width: 500px) {
//     width: 50px;
//     height: 50px;

//     span {
//         font-size: 30px;
//     }
// }

// @media only screen and (min-width: 1470px) {
//     right: -70px;
// }
//     }
// `;

const pagePathMap = {
    projects: "projects?page=1",
    seminar: "seminar?page=1",
    publications: "publications?page=1",
    news: "news?page=1",
    album: "album?page=1",
};

export default withRouter(
    ({
        children,
        history: {
            location: { pathname },
        },
    }) => {
        const pathnameArr = pathname.split("/");

        const paths = [
            {
                name: pathnameArr[1]
                    .split("_")
                    .map((p) => p.charAt(0).toUpperCase() + p.substr(1))
                    .join(" "),
                path: `/${pathnameArr[1]}`,
            },
        ];

        if (pathnameArr.length > 2) {
            paths.push({
                name: pathnameArr[2]
                    .split("_")
                    .map((p) => p.charAt(0).toUpperCase() + p.substr(1))
                    .join(" "),
                path: `/${pathnameArr[1]}/${
                    pagePathMap[pathnameArr[2]] ?? pathnameArr[2]
                }`,
            });
        }

        window.scrollTo(0, 0);

        return (
            <Main>
                <NavBarContainer>
                    <div className="inner">
                        <NavBar>
                            <Link to="/">
                                <span className="material-icons navBarHome">
                                    home
                                </span>
                            </Link>
                            <Link to="/">
                                <span>Home</span>
                            </Link>
                            {paths.map(({ name, path }) => (
                                <Link
                                    to={path}
                                    key={path}
                                    className="currentPath"
                                >
                                    <span className="material-icons-outlined navBarArrow">
                                        arrow_forward_ios
                                    </span>
                                    <NavBarPath>{name}</NavBarPath>
                                </Link>
                            ))}
                        </NavBar>
                    </div>
                </NavBarContainer>
                <Content>{children}</Content>
            </Main>
        );
    }
);
