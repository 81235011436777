/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import PageTitle from "../../../Components/PageTitle";
import styled from "styled-components";

const Title = styled.h2`
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
`;

const Subject = styled.p`
    text-align: center;
    color: #7e838f;
`;

const Container = styled.div`
    width: 100%;
    margin-top: 141px;
    position: relative;
`;

const Item = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 150px;
    }

    @media only screen and (max-width: 765px) {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
`;

const Step = styled.div`
    font-size: 22px;
    color: #769af2;
    font-weight: 600;
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 15px 2px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    margin-right: 83px;
    background-color: #fff;

    @media only screen and (max-width: 765px) {
        margin-right: 0;
        min-width: initial;
        min-height: initial;
        width: 100px;
        height: 100px;
        font-size: 18px;
    }
`;

const DataTitle = styled.div`
    font-size: 19px;
    font-weight: 600;
    color: #595f6f;
    margin-bottom: 13px;

    @media only screen and (max-width: 765px) {
        text-align: center;
    }
`;

const DataContents = styled.div`
    font-size: 16px;
    color: #7e838f;

    @media only screen and (max-width: 765px) {
        text-align: center;
    }
`;

const Line = styled.div`
    position: absolute;
    height: 100%;
    top: 0;
    left: 58px;
    border-right: 2px dashed #bcbec4;
    z-index: -1;

    @media only screen and (max-width: 765px) {
        display: none;
    }
`;

export default ({data}) => (
    <Page title="Contact">
        <div className="shortInner">
            <PageTitle
                title="Apply"
                subTitle={data && data.introData[5]["Contents"]}
            ></PageTitle>
            <Container>
                <Line></Line>
                {data && data.applyData.map((apply, idx) => (
                    <Item key={idx}>
                        <Step>{`Step ${idx + 1}.`}</Step>
                        <div>
                            <DataTitle>{apply.Title}</DataTitle>
                            <DataContents>{apply.Contents}</DataContents>
                        </div>
                    </Item>
                ))}
            </Container>
        </div>
    </Page>
);
