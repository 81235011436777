/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import "github-markdown-css";
import { Markdown } from "../utils";

const Date = styled.div`
    color: #7e838f;
    margin-bottom: 10px;
    border-bottom: 1px solid #595f6f;
    padding-bottom: 10px;
`;

const Title = styled.div`
    padding-bottom: 14px;
    font-size: 23px;
    font-weight: 600;
    color: #343c50;
`;

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    color: #343c50;
    & p {
        color: #343c50;
        width: 100%;
        font-size: 16px;
    }
    border-bottom: 1px dashed #e8e9eb;
`;

const Img = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    & img {
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
`;

const BackBtnBox = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    border-top: 1px solid #595f6f;
`;

const BackBtn = styled.button`
    background-color: #507cf2;
    border: 0;
    outline: 0;
    color: #fff;
    width: 120px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    cursor: pointer;
`;

const ItemCategory = styled.ul`
    display: flex;
    & > li {
        font-size: 13px;
        background-color: #769af2;
        color: #fff;
        padding: 1px 10px;
        border-radius: 100px;
    }

    & > li:not(:last-child) {
        margin-right: 13px;
    }

    margin-bottom: 10px;
`;

const ItemInfo = styled.ul`
    display: flex;
    align-items: center;
    & > li {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #7e838f;

        & > span:first-child {
            display: block;
            width: 24px;
            color: #769af2;
            font-size: 18px;
        }

        &:not(:last-child) {
            margin-right: 32px;
        }
    }
    padding-bottom: 10px;
    border-bottom: 1px solid #595f6f;
`;

const HashtagBox = styled.div`
    margin-top: 20px;
    margin-bottom: 79px;
    width: 100%;
`;

const Hashtag = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #7e838f;

    & div {
        font-size: 15px;

        & > span:first-child {
            margin-right: 2px;
            color: #507cf2;
        }

        &:hover > span:last-child {
            color: #595f6f;
            text-decoration: underline;
        }
    }

    & a:not(:last-child) {
        margin-right: 12px;
    }
`;

const IframeBox = styled.div`
    position: relative;
    padding-top: 56%;
    width: 100%;
    height: 0;
    margin-top: 40px;

    iframe {
        /* padding: 0 6%; */
        max-width: 1100px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
    }
`;

const CommentBox = styled.div`
    padding: 49px 10px;
    padding-bottom: 120px;
`;

const Comment = styled.div`
    font-size: 14px;
    max-width: 1000px;
    margin: 0 auto;
    .imgBox {

    }

    &:not(:last-child) {
        margin-bottom: 70px;
    }

    & > div {
        display: flex;
        align-items: center;
        gap: 25px;
        margin-bottom: 12px;

        .imgBox {
            width: 52px;
            height: 52px;
            background-color: #000;
            border-radius: 100%;
            overflow: hidden;
        }

        .profile {
            display: flex;
            align-items: flex-end;
            gap: 31px;

            span:first-child {
                color: #769af2;
                font-size: 16px;
            }
            span:last-child {
                color: #7e838f;
            }
        }
    }
`;

const MarkdownBox = styled.div`
    margin: 0 auto;
    margin-top: 54px;
    margin-bottom: 60px;
    max-width: 1000px;
    width: 100%;

    p, li, a, span {
        font-family: "Poppins", "Noto Sans KR", sans-serif;
        font-size: 16px;
    }
`;

const File = styled.div`
    width: 100%;
    padding: 14px 0;
    margin-left: 16px;
    margin-bottom: 0 !important;

    & a {
        background-color: #e8e9eb;
        border: 1px solid #bcbec4;
        padding: 5px 8px;
        display: inline-block;
    }

    & div:not(:last-child) {
        margin-bottom: 15px;
    }
`;

const CommentTitle = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto;
    align-items: center;
    margin-bottom: 39px;

    h3 {
        font-size: 24px;
        font-weight: 600;
        color: #595f6f;
    }
`;

const AddComment = styled.a`
    border: 1px solid #7e838f;
    border-radius: 10px;
    color: #7e838f;
    display: flex;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        display: block;
        width: 16px;
    }
    
    img:nth-child(2) {
        display: none;
    }

    :hover {
        background-color: #595f6f;
        color: #fff;

        img:nth-child(1) {
            display: none;
        }
        img:nth-child(2) {
            display: block;
        }
    }
`;

export default ({ data, goBack, history, backIsHome }) => {
    return (
        <>
            <ItemCategory>
                {data.Tags &&
                    data.Tags.map((tag, i) => (
                        <li key={`${data._id}${tag}`}>{tag}</li>
                    ))}
            </ItemCategory>
            <Title>{data.Title}</Title>
            <ItemInfo>
                <li>
                    <span className="material-icons-outlined">people</span>
                    <span>{data.Presenter.join(", ")}</span>
                </li>
                <li>
                    <span className="material-icons-outlined">
                        calendar_today
                    </span>
                    <span>{data.Date}</span>
                </li>
            </ItemInfo>
            <Contents>
                {data.Url && (
                    <IframeBox>
                        <iframe
                            src={data.Url}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </IframeBox>
                )}
                <MarkdownBox>
                    {data["content_data"] && (
                        <Markdown text={data["content_data"]} />
                    )}
                </MarkdownBox>
                {data.Attachment && data.Attachment.length > 0 && (
                    <File>
                        {data.Attachment.map((attachment) => (
                            <div key={attachment.url}>
                                <a href={attachment.url} target="_blank">
                                    {attachment["file_name"]}
                                </a>
                            </div>
                        ))}
                    </File>
                )}
            </Contents>
            <CommentBox>
                <CommentTitle>
                    <h3>Comment</h3>
                    <AddComment href={data["Comment_Url"]} target="_blank">
                        <img src="/img/notion-logo.svg" />
                        <img src="/img/notion-logo-hover.svg" />
                        <span>Add Comment</span>
                    </AddComment>
                </CommentTitle>
                {data.comment &&
                    data.comment[0].map((comment) => (
                        <Comment key={comment.Writer}>
                            <div>
                                <div className="profile">
                                    <span>{comment.Writer}</span>
                                    <span>{comment.date}</span>
                                </div>
                            </div>
                            <p>{comment.Content}</p>
                        </Comment>
                    ))}
            </CommentBox>
            <BackBtnBox>
                <BackBtn
                    onClick={() => {
                        backIsHome || backIsHome === null ? history.push("/research/seminar?page=1") : goBack()
                }}>목록</BackBtn>
            </BackBtnBox>
        </>
    );
};
