const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        _id: i,
        Image: "https://scikit-plot.readthedocs.io/en/stable/_images/plot_confusion_matrix.png",
        Title: `${
            i + 1
        }번 resource 테스트 데이터 제목입니다. 테스트 데이터 제목입니다.`,
        Date: `2021-08-0${Math.floor(i / 20) + 1}`,
        Content: `${i + 1}번 resource 테스트 내용입니다.`,
        Category: [`category${Math.floor(i / 25) + 1}`],
        Keyword: ["Data Augmentation", "Emotion Recognition", "Question Answering"]
    });
}

export default data;
