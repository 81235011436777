/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";

const Footer = styled.footer`
    padding: 40px 0;

    & > .inner {
        padding: 0 40px;
    }
`;
const FooterTop = styled.div`
    text-align: center;
    font-size: 22px;
    color: #7e838f;
    font-weight: 600;
    padding-bottom: 50px;
`;

const Contact = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    & > div:last-child {
        display: flex;
        gap: 100px;
    }

    @media only screen and (max-width: 920px) {
        justify-content: space-between;
        align-items: center;
        flex-direction: column-reverse;
        gap: 0px;

        & > div:last-child {
            display: flex;
            gap: 0px;
            width: 100%;
            justify-content: space-around;
            margin-bottom: 100px;
        }

        & > div:last-child {
            /* flex-direction: column; */
        }
    }

    @media only screen and (max-width: 600px) {
        & > div:last-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 70px;
        }
    }
`;

const LabName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & p {
        font-size: 18px;
        font-weight: 500;
        margin-top: 10px;
        white-space: nowrap;
    }

    @media only screen and (max-width: 865px) {
        align-items: center;
        & p {
            font-size: 16px;
        }
    }
`;
const LabLogo = styled.div`
    img {
        width: 200px;
    }

    @media only screen and (max-width: 865px) {
        img {
            width: 180px;
        }
    }
`;

const InfoBox = styled.div`
    & > h4 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    & > div {
        display: grid;
        grid-template-columns: 120px 1fr;

        span:nth-child(odd) {
            font-size: 18px;
            font-weight: 600;
        }

        span:nth-child(even) {
            font-size: 17px;
            font-weight: 400;
            color: #7e838f;
            cursor: pointer;

            &:hover {
                color: #769af2;
                text-decoration: underline;
            }
        }
    }

    @media only screen and (max-width: 865px) {
        & {
            font-size: 15px;
        }
        & > h4 {
            font-size: 18px;
        }
    }
`;

const footerInfo = [
    {
        title: "정상근 교수",
        tel: "042) 821-5444",
        email: "hugman@cnu.ac.kr",
        address: "대전광역시 유성구 대학로 99 충남대학교 공대 5호관 624호"
    },
    {
        title: "대학원 연구실",
        tel: "042) 821-7444",
        email: "jisu.jung5@gmail.com (박사과정 정지수)",
        address: "대전광역시 유성구 대학로 99 충남대학교 공대 5호관 630호"
    }
];

const clickInfo = (text) => {
    const clipBoard = document.querySelector(".clipBoard");
    const successBox = document.querySelector(".successBox");

    clipBoard.classList.remove("hide");
    clipBoard.value = text;
    clipBoard.select();

    document.execCommand("copy");

    clipBoard.blur();

    successBox.style.transition = "none";
    successBox.style.opacity = "1";
    successBox.style.zIndex = "9999";
    setTimeout(() => {
        successBox.style.transition =
            "opacity 0s linear 1.5s, z-index 0s linear 1.5s";
        successBox.style.opacity = "0";
        successBox.style.zIndex = "-9999";
    }, 10);
}


export default () => (
    <Footer>
        <div className="inner">
            <FooterTop>Contact Us</FooterTop>
            <Contact>
                <LabName>
                    <LabLogo>
                        <img src="/img/logo.png" />
                    </LabLogo>
                    <p>
                        충남대학교 컴퓨터공학과
                        <br />
                        지능 소프트웨어 연구실
                    </p>
                </LabName>
                <div>
                    {footerInfo.map((info, idx) => (
                        <InfoBox key={info.title}>
                            <h4>{info.title}</h4>
                            <div>
                                <span>Tel</span>
                                <span onClick={() => clickInfo(info.tel)}>Click to copy</span>
                                <span>E-mail</span>
                                <span onClick={() => clickInfo(info.email)}>Click to copy</span>
                                <span>Address</span>
                                <span onClick={() => clickInfo(info.address)}>Click to copy</span>
                            </div>
                        </InfoBox>
                    ))}
                </div>
            </Contact>
        </div>
    </Footer>
);
