import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";

const OPEN = "add";
const CLOSE = "remove";

const Container = styled.div`
    padding: 50px;
    border: 1px solid #979797;
    background-color: #ffffff;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        border: 1px solid #769af2;
        box-shadow: 0 2px 15px 4px rgba(0, 0, 0, 0.1);

        & .top {
            color: ${({open}) => open ? "#507cf2" : "#769af2"};
        }
    }

    @media only screen and (max-width: 765px) {
        padding: 40px 30px;
    }

    @media only screen and (max-width: 500px) {
        padding: 30px 20px;
    }
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${({open}) => open ? "#507cf2" : "#595f6f"};
`;

const Title = styled.h2`
    font-size: 22px;
    margin-right: 20px;
    font-weight: 600;

    @media only screen and (max-width: 500px) {
        font-size: 18px;
    }
`;
const Btn = styled.span`
    font-size: 32px;
    font-weight: 900;

    @media only screen and (max-width: 765px) {
        font-size: 27px;
    }
`;

const Contents = styled.p`
    display: ${({ open }) => open ? "block" : "none"};
    color: #7e838f;
    font-size: 16px;
    margin-top: 15px;
    margin-right: 52px;

    @media only screen and (max-width: 765px) {
        margin-right: 0;
    }
`;

export default ({title, isOpen, children}) => {
    const useClick = (isOpen) => {
        const [open, setOpen] = useState(isOpen);

        const clickBtn = () => {
            setOpen(!open);
        }
        return {
            open,
            clickBtn
        }
    }

    const { open, clickBtn } = useClick(isOpen);

    return (
        <Container open={open} onClick={clickBtn}>
            <Top className="top" open={open}>
                <Title>{title}</Title>
                <Btn className="material-icons-outlined">
                    {open ? CLOSE : OPEN}
                </Btn>
            </Top>
            <Contents open={open}>
                {children}
            </Contents>
        </Container>
    )
}