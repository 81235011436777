/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import PageTitle from "../../../Components/PageTitle";
import Accordion from "../../../Components/Accordion";
import styled from "styled-components";

const Container = styled.div`
    margin-top: 70px;
`;

const Wrap = styled.div`
    display: flex;
    justify-content: center;

    &:not(:last-child) {
        margin-bottom: 30px;
    }
`;

export default ({ faqs }) => (
    <Page title="Contact">
        <div className="shortInner">
            <PageTitle title="Frequently Asked Questions"></PageTitle>
            <Container>
                {faqs &&
                    faqs.map((faq, idx) => (
                        <Wrap key={faq._id}>
                            <Accordion title={faq.Question} isOpen={false}>
                                {faq.Answer}
                            </Accordion>
                        </Wrap>
                    ))}
            </Container>
        </div>
    </Page>
);
