import React from "react";
import styled from "styled-components";

const Title = styled.h2`
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
`;

const SubTitle = styled.p`
    text-align: center;
    color: #7e838f;
`;

export default ({ title, subTitle }) => (
    <div>
        {title && (
            <Title>{ title }</Title>
        )}
        {subTitle && (
            <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }}></SubTitle>
        )}
    </div>
)