/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { usePaging } from "../../../utils";
import Presenter from "./publications.presenter";
import { isoftlab, test } from "../../../api";
import { withRouter } from "react-router-dom";

const MAX_VIEW_PAGE = 5;
const MAX_VIEW_DATA = 8;
const searchOption = {
    search: [
        "Author",
        "Title",
        "Name",
        "ImpactFactor",
        "Date",
        "DOI",
        "ShortName",
        "Keyword",
    ],
    category: "Tags",
};

const categoryOption = [
    "International Journal",
    "International Conference",
    "Domestic Journal",
    "Domestic Conference",
];


export default withRouter((props) => {
    return (
        <Presenter
            {...usePaging({
                dataFn: isoftlab.getPublications,
                maxViewPage: MAX_VIEW_PAGE,
                maxViewData: MAX_VIEW_DATA,
                searchOption,
                urlProps: props,
            })}
            categoryOption={categoryOption}
        />
    );
});
