/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import Profile from "../../../Components/Profile";
import StudentCard from "../../../Components/StudentCard";
import {Container, CardContainer} from "../../../Components/Student-Alumnni";
import styled from "styled-components";


export default ({
    currentId,
    alumniData,
    divisionData,
    profileRef,
    clickCard,
    profileClose
}) => (
    <Page title="Students">
        <div className="inner">
            {alumniData && (
                <Container>
                    <Profile profileRef={profileRef} className="profile" profile={alumniData[currentId]} isStudent={true} profileClose={profileClose}></Profile>
                    <CardContainer>
                        {divisionData["Ph.D Student"] && (
                            <StudentCard
                                title="Ph.D Students"
                                data={divisionData["Ph.D Student"]}
                                currentId={currentId}
                                clickCard={clickCard}
                                isStudent={false}
                            />
                        )}
                        {divisionData["M.S. Student"] && (
                            <StudentCard
                                title="M.S. Students"
                                data={divisionData["M.S. Student"]}
                                currentId={currentId}
                                clickCard={clickCard}
                                isStudent={false}
                            />
                        )}
                        {divisionData["Intern Student"] && (
                            <StudentCard
                                title="Intern Students"
                                data={divisionData["Intern Student"]}
                                currentId={currentId}
                                clickCard={clickCard}
                                isStudent={false}
                            />
                        )}
                    </CardContainer>
                </Container>
            )}
        </div>
    </Page>
);
