/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const SearchBar = styled.div`
    width: 100%;
    max-width: 550px;
    height: 60px;
    border: 2px solid #769af2;
    border-radius: 60px;
    display: flex;
    align-items: center;

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 850px) {
        height: 50px;
    }
`;

const SearchBtn = styled.button`
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    background-color: transparent;
    margin-left: 15px;
    & > span {
        font-size: 40px;
        color: #769af2;
        margin-top: 6px;
    }
    /* cursor: pointer; */

    @media only screen and (max-width: 850px) {
        & > span {
            font-size: 30px;
        }
    }
`;

const SearchInput = styled.input`
    height: 40px;
    font-size: 18px;
    width: 100%;
    margin-left: 10px;
    margin-right: 20px;
    border: 0;
    outline: 0;
    color: #769af2;

    &::placeholder {
        font-size: 18px;
        color: #769af2;
    }

    @media only screen and (max-width: 850px) {
        &::placeholder {
            font-size: 18px;
            color: #769af2;
        }
    }
`;

export default ({ handleSearch, searchInput, initKeyword }) => (
    <SearchContainer>
        <SearchBar>
            <SearchBtn>
                <span className="material-icons-outlined">search</span>
            </SearchBtn>
            <SearchInput
                className="searchInput"
                ref={searchInput}
                placeholder="Search..."
                onChange={handleSearch}
                value={initKeyword ?? ""}
            />
        </SearchBar>
    </SearchContainer>
);
