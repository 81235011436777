import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Markdown } from "../utils";
import "github-markdown-css";

const NewsItem = styled.div`
    border: 1px solid #bcbec4;
    width: 100%;
    height: 223px;
    border-radius: 10px;
    padding: 22px 20px;
    box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
        color: #7e838f;
        font-weight: 600;

        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        /* margin-bottom: 12px; */
    }

    & > .newsDate {
        display: flex;
        align-items: center;
        gap: 5px;

        span:first-child {
            color: #769af2;
            font-size: 16px;
        }
        span:last-child {
            margin-top: 3px;
            font-size: 14px;
            color: #7e838f;
        }
    }

    &:hover {
        border: 1px solid #507cf2;

        h4 {
            color: #595f6f;
        }

        .newsContent * {
            color: #7e838f;
        }

        .newsDate {
            span:first-child {
                color: #507cf2;
            }

            span:last-child {
                color: #595f6f;
            }
        }
    }
`;

const NewsContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #7e838f;
    /* margin-bottom: 13px; */

    * {
        font-size: 13px;
        color: #bcbec4 !important;
    }
`;

export default ({item, clone}) => (
    <Link
        className={clone ? clone : ""}
        to={{
            pathname: `/board/news/contents/${item._id}`,
            state: {
                data: item,
                backIsHome: true
            },
        }}
    >
        <NewsItem>
            <h4>{item.Title}</h4>
            <NewsContent className="newsContent">
                {item["content_data"] && (
                    <Markdown
                        text={
                            item["content_data"]
                        }
                    />
                )}
            </NewsContent>
            <div className="newsDate">
                <span className="material-icons-outlined">
                    calendar_today
                </span>
                <span>{item.Date}</span>
            </div>
        </NewsItem>
    </Link>
)