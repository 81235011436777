import React from "react";
import Presenter from "./resource.presenter";
import { usePaging, getUrl, paramsParsing } from "../../utils";
import { isoftlab } from "../../api";
import { withRouter } from "react-router-dom";

const MAX_VIEW_PAGE = 5;
const MAX_VIEW_DATA = 12;
const searchOption = {
    search: ["Title", "Date", "Content", "Keyword"],
    category: "Category"
};

const categoryOption = [
    "Machine Learning",
    "NLP Tasks"
];

export default withRouter((props) => {
    return (
        <Presenter
            {...usePaging({
                dataFn: isoftlab.getResource,
                maxViewPage: MAX_VIEW_PAGE,
                maxViewData: MAX_VIEW_DATA,
                searchOption,
                urlProps: props,
            })}
            categoryOption={categoryOption}
        />
    );
});
