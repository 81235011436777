/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";

const TotalBox = styled.div`
    margin-top: 45px;
    padding-bottom: 15px;
    border-bottom: 1px solid #595f6f;
    font-size: 19px;
    color: #595f6f;
    & > span:nth-child(2) {
        color: #507cf2;
        font-size: 20px;
        font-weight: 600;
    }

    & > span:nth-child(3) {
        margin-left: 10px;
        font-size: 14px;

        display: none;

        &.show {
            display: inline;
        }
    }

    @media only screen and (max-width: 800px) {
        font-size: 17px;

        & > span:nth-child(2) {
            font-size: 18px;
        }

        & > span:nth-child(3) {
            font-size: 13px;
            display: none;

            &.show {
                display: inline;
            }
        }
    }
`;

export default ({ total, isFilltered, en }) => (
    <TotalBox>
        <span>Number of entries: </span>
        <span>{total}</span>
        <span className={isFilltered ? "show" : ""}>(Filtered)</span>
    </TotalBox>
);
