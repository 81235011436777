/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../../Components/Page";
import Post from "../../../../Components/AlbumPost";

export default ({ album, goBack, history, backIsHome }) => (
    <Page>
        <div className="inner">
            {album && <Post data={album} isNews={false} goBack={goBack} history={history}
                    backIsHome={backIsHome} />}
        </div>
    </Page>
);
