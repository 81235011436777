/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../Components/Page";
import PageController from "../../Components/PageController";
import styled from "styled-components";
import TotalBox from "../../Components/TotalBox";
import SearchBox from "../../Components/SearchBox";
import ResourceCard from "../../Components/ResourceCard";

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;

    & > div:last-child {
        max-width: 450px;
    }

    @media only screen and (max-width: 850px) {
        flex-direction: column;
        align-items: center;

        & > div:last-child {
            margin-top: 20px;
        }
    }
`;

const CategoryContainer = styled.div`
    width: 100%;
    max-width: 333px;
    margin-right: 40px;
    position: relative;

    @media (hover:hover) {
        &:hover {
            & .lessBtn {
                display: block;
            }
    
            & .moreBtn {
                display: none;
            }
    
            & .categoryList {
                display: block;
            }
        }
    
    }

    li {
        list-style-type: none;
    }

    &.open {
        & .lessBtn {
            display: block;
        }

        & .moreBtn {
            display: none;
        }

        & .categoryList {
            display: block;
        }
    }

    @media only screen and (max-width: 850px) {
        max-width: 450px;
        margin-right: 0;
    }
`;

const CategoryBox = styled.div`
    width: 100%;
    height: 60px;
    border: 2px solid #595f6f;
    border-radius: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    & .lessBtn {
        display: none;
    }

    &:focus {
        outline: none;
    }

    @media only screen and (max-width: 850px) {
        height: 50px;
    }
`;

const BtnBox = styled.div`
    padding-top: 6px;
    & > span {
        font-size: 30px;
        color: #595f6f;
    }
`;

const Category = styled.div`
    font-size: 18px;
    text-align: center;
    color: #595f6f;
    width: 100%;
`;

const CategoryList = styled.div`
    position: absolute;
    width: 100%;
    background-color: #fff;
    padding-top: 10px;
    z-index: 10;
    display: none;

    & > ul {
        padding: 20px;
        border: 1px solid #bcbec4;
        box-shadow: 0 9px 11px 2px rgba(0, 0, 0, 0.3);
        padding: 21px 16px 21px 17px;
        border-radius: 10px;

        & > li {
            width: 100%;
            padding: 10px 18px;
            border: 1px solid #e8e9eb;
            color: #595f6f;
            border-radius: 10px;
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 5px;
            }

            &.current {
                background-color: #507cf2;
                color: #fff;
            }

            &:not(.current):hover {
                border: 1px solid #507cf2;
                color: #507cf2;
            }
        }
    }
`;

const ItemGrid = styled.div`
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    & > a > div {
        height: 100%;
    }
    margin-top: 39px;
`;

export default ({
    categoryOption,
    filteredData,
    viewData,
    currentPage,
    pageList,
    keyword,
    category,
    searchInput,
    clickPageNum,
    clickNextBtn,
    clickPrevBtn,
    clickLastBtn,
    clickFirstBtn,
    clickCategory,
    clickCard,
    handleSearch,
}) => {
    const clickReSourceCategory = (event, isAll, inputCategory, keyword) => {
        const { target } = event;
        const ul = target.parentNode;
        const categoryList = ul.parentNode;

        ul.querySelector(".current").classList.remove("current");
        target.classList.add("current");

        categoryList.classList.add("hide");
        setTimeout(() => {
            categoryList.classList.remove("hide");
        }, 50);
        
        const categoryBox = document.querySelector(".categoryBox");

        if (categoryBox.classList.contains("open")) {
            categoryBox.classList.remove("open");
        }

        clickCategory(isAll, inputCategory, keyword);
    };

    const clickCategoryBtn = (event) => {
        document.querySelector(".categoryBox").classList.toggle("open");
    }

    return (
        <Page title="Resource">
            <div className="inner">
                <SearchContainer>
                    <CategoryContainer className="categoryBox">
                        <CategoryBox onClick={clickCategoryBtn}>
                            <Category>
                                {category && category !== ""
                                    ? category
                                    : "All Category"}
                            </Category>
                            <BtnBox>
                                <span className="material-icons-outlined moreBtn">
                                    expand_more
                                </span>
                                <span className="material-icons-outlined lessBtn">
                                    expand_less
                                </span>
                            </BtnBox>
                        </CategoryBox>
                        <CategoryList className="categoryList">
                            <ul>
                                <li
                                    className={!category ? "current" : ""}
                                    onClick={(event) => {
                                        clickReSourceCategory(
                                            event,
                                            true,
                                            null,
                                            keyword
                                        );
                                    }}
                                >
                                    All Category
                                </li>
                                {categoryOption &&
                                    categoryOption.map((item, idx) => (
                                        <li
                                            key={idx}
                                            className={
                                                category === item
                                                    ? "current"
                                                    : ""
                                            }
                                            onClick={(event) => {
                                                clickReSourceCategory(
                                                    event,
                                                    false,
                                                    item,
                                                    keyword
                                                );
                                            }}
                                        >
                                            {item}
                                        </li>
                                    ))}
                            </ul>
                        </CategoryList>
                    </CategoryContainer>
                    <SearchBox
                        handleSearch={(event) => {
                            handleSearch(event, category);
                        }}
                        searchInput={searchInput}
                        initKeyword={keyword}
                    />
                </SearchContainer>
                {filteredData && (
                    <TotalBox
                        total={filteredData.length}
                        isFilltered={(keyword && keyword !== "") || category}
                        en={true}
                    />
                )}

                <ItemGrid>
                    {viewData &&
                        viewData.map((item, idx) => (
                            <ResourceCard
                                key={item._id}
                                item={item}
                                page={currentPage}
                                category={category}
                                keyword={keyword}
                                clickCard={clickCard}
                            />
                        ))}
                </ItemGrid>

                <PageController
                    clickFirstBtn={clickFirstBtn}
                    clickLastBtn={clickLastBtn}
                    clickNextBtn={clickNextBtn}
                    clickPageNum={clickPageNum}
                    clickPrevBtn={clickPrevBtn}
                    filteredData={filteredData}
                    currentPage={currentPage}
                    pageList={pageList}
                />
            </div>
        </Page>
    );
};
