/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NewsItem from "../../Components/Home-NewsItem";
import ProjectCard from "../../Components/ProjectCard";
import PublicationCard from "../../Components/PublicationCard";
import SeminarCard from "../../Components/SeminarCard";
import AnimationBox from "../../Components/Home-Animation";

import "github-markdown-css";
import { Markdown } from "../../utils";

const Container = styled.div`
`;

const Section = styled.section`
    width: 100%;
    &.short {
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
    }
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 100px 0;

    & .itemShadow {
        display: block;
        box-shadow: 0 4px 15px 4px rgba(0, 0, 0, 0.1);
    }

    & > div {
        width: 100%;
    }
`;

const SectionTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 42px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: #7e838f;
        text-align: center;
    }

    @media only screen and (max-width: 865px) {
        h1 {
            font-size: 36px;
        }
    }

    @media only screen and (max-width: 500px) {
        h1 {
            font-size: 32px;
        }

        p {
            font-size: 16px;
        }
    }

    padding-bottom: 80px;
`;

const FirstSection = styled.div`
    width: 100%;
    /* height: 100%; */
`;

const MainBox = styled.div`
    position: relative;
    width: 100%;
    min-height: 45vh;
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 70%;
        min-height: 40%;
        display: block;
        object-fit: cover;
        object-position: center;
        z-index: -1;

        @media only screen and (max-width: 500px) {
            display: none;
        }
    }
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
`;

const MainTextBox = styled.div`
    /* position: absolute; */
    padding: 50px 0;
    h1 {
        font-size: 42px;
        font-weight: 700;
        margin-bottom: 26px;
    }

    h4 {
        font-size: 18px;
        color: #7e838f;
        max-width: 520px;
    }

    @media only screen and (max-width: 865px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        h1 {
            font-size: 36px;
            line-height: 1.2;
        }

        & > div {
            text-align: center;
        }
    }

    @media only screen and (max-width: 500px) {
        h1 {
            font-size: 30px;
        }

        h4 {
            font-size: 16px;
        }
    }
`;

const NewsBox = styled.div`
    margin-top: 60px;

    h2 {
        font-size: 24px;
        color: #595f6f;
        font-weight: 700;
        margin-bottom: 17px;
    }

    /* & > div {
        & > div:first-child {
            display:flex;
            gap: 36px;
            height: 223px;

            a {
                display: block;
                width: 100%;
            }
        }
    } */
`;

const FlexBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 75px;

    @media only screen and (max-width:865px) {
        flex-direction: column;
    }
`;

const ResearchCard = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 100px;
    gap: 40px;
    & > a {
        padding: 25px;
        box-shadow: 0 2px 15px 10px rgba(89, 95, 111, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 200px;
        border-radius: 10px;

        & > span {
            color: #fff;
            display: flex;
            min-width: 72px;
            min-height: 72px;
            width: 72px;
            height: 72px;
            background-color: #507cf2;
            box-shadow: 0 4px 15px 6px rgba(80, 124, 242, 0.3);
            justify-content: center;
            align-items: center;
            font-size: 40px;
            border-radius: 100%;
        }

        & > div {
            margin-top: 20px;
            h5 {
                font-weight: 600;
                font-size: 18px;
                line-height: 1.2;
                color: #343c50;
                text-align: center;
            }
            span {
                font-size: 13px;
                color: #7e838f;
            }
        }
    }

    @media only screen and (max-width:865px) {
        & > a {
            max-width: 150px;

            & > div {
                h5 {
                    font-size: 16px;
                }
            }
        }
        gap: 30px;
    }

    @media only screen and (max-width:500px) {
        & > a {
            width: calc(50% - 15px);
            max-width: initial;

            & > div {
                h5 {
                    font-size: 15px;
                }
            }
        }
    }

    & > a:nth-child(odd) {
        & > span {
            background-color: #f6b33f;
            box-shadow: 0 4px 15px 6px rgba(246, 179, 63, 0.3);
        }
    }
`;

const TextBox = styled.div`
    width: 100%;
    h1 {
        margin-bottom: 13px;
        color: #343c50;
        font-size: 42px;
        font-weight: 600;
    }

    p {
        font-size: 16px;
        color: #7e838f;
    }

    @media only screen and (max-width:865px) {
        h1, p, div {
            text-align: center;
        }

        h1 {
            font-size: 36px;
            line-height: 1.2;
        }

        & > div {
            display: none;
        }
    }

    @media only screen and (max-width: 500px) {
        h1 {
            font-size: 30px;
        }

        h4 {
            font-size: 16px;
        }
    }
`;

const HomeBtn = styled.button`
    background-color: #769af2;
    color: #fff;
    box-shadow: 0 5px 15px 4px rgba(118, 154, 242, 0.5);
    border: 0;
    outline: 0;
    padding: 20px 40px;
    font-size: 19px;
    border-radius: 100px;
    cursor: pointer;

    &:hover {
        background-color: #507cf2;
    }

    @media only screen and (max-width: 865px) {
        font-size: 19px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 16px;
        padding: 15px 40px;
    }
`;

const CardBox = styled.div`
    & > div > div:first-child {
        & > a {
            & > div {
                box-shadow: 0 4px 15px 4px rgba(0, 0, 0, 0.1);
            }
        }
    }
`;

const BtnBox = styled.div`
    margin-top: 46px;
`;

const BtnCenterBox = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 46px;
`;

const PublicationBox = styled.div`
    width: 100%;
    & > div:last-child {
        display: none;
    }

    /* & > a {
        & > div {
            box-shadow: 0 4px 15px 4px rgba(0, 0, 0, 0.1);
        }
    } */
`;

const researchList = [
    { icon: "create", title: "Updated NLP" },
    { icon: "forum", title: "Interactive Neural Search" },
    { icon: "flag", title: "Semantic Representation" },
    { icon: "analytics", title: "Data-Centric NLP" },
    { icon: "biotech", title: "AI + BIO" },
];

export default ({ datas }) => (
    <div className="inner">
        <Container>
            <Section>
                <FirstSection>
                    <MainBox>
                        <MainTextBox>
                            <h1>Intelligent Software Laboratory</h1>
                            {datas.intros && <h4>{datas.intros[0]["Contents"]}</h4>}
                            <BtnBox>
                                <Link to="/contact/apply">
                                    <HomeBtn>CONTACT US</HomeBtn>
                                </Link>
                            </BtnBox>
                        </MainTextBox>
                        <img src="/img/isoftlab.jpg" />
                    </MainBox>
                    <NewsBox>
                        <h2>News</h2>
                        <AnimationBox initDirection="row">
                            {datas.news && (
                                <React.Fragment>
                                    <NewsItem clone="clone" item={datas.news[1]}></NewsItem>
                                    <NewsItem clone="clone" item={datas.news[2]}></NewsItem>
                                    {datas.news.map((item) => (
                                        <NewsItem key={item._id} item={item} />
                                    ))}
                                    <NewsItem clone="clone" item={datas.news[0]}></NewsItem>
                                    <NewsItem clone="clone" item={datas.news[1]}></NewsItem>
                                    {/* <AnimationCircle className="ani-circle">
                                        <div className="current"></div>
                                        <div></div>
                                        <div></div>
                                    </AnimationCircle> */}
                                </React.Fragment>
                            )}
                        </AnimationBox>
                    </NewsBox>
                </FirstSection>
            </Section>
            <Section>
                <div>
                    <SectionTitle>
                        <h1>Research Areas</h1>
                        {datas.intros && <p>{datas.intros[1]["Contents"]}</p>}
                    </SectionTitle>
                    <ResearchCard>
                        {researchList.map((research) => (
                            <Link
                                key={research.title}
                                to={`/research/projects?page=1&category=${encodeURI(research.title)}`}
                            >
                                <span className="material-icons-outlined">
                                    {research.icon}
                                </span>
                                <div>
                                    <h5>{research.title}</h5>
                                </div>
                            </Link>
                        ))}
                    </ResearchCard>
                    <BtnCenterBox>
                        <Link to="/research/research_areas">
                            <HomeBtn>RESEARCH AREAS</HomeBtn>
                        </Link>
                    </BtnCenterBox>
                </div>
            </Section>
            <Section>
                <div>
                    <SectionTitle>
                        <h1>Projects</h1>
                        {datas.intros && <p>{datas.intros[2]["Contents"]}</p>}
                    </SectionTitle>
                    <CardBox>
                        <AnimationBox initDirection="row">
                            {datas.projects && (
                                <React.Fragment>
                                    <ProjectCard clone="clone" item={datas.projects[1]} backIsHome={true} />
                                    <ProjectCard clone="clone" item={datas.projects[2]} backIsHome={true} />
                                    {datas.projects.map((item) => (
                                            <ProjectCard item={item} key={item._id} backIsHome={true} />
                                    ))}
                                    <ProjectCard clone="clone" item={datas.projects[0]} backIsHome={true} />
                                    <ProjectCard clone="clone" item={datas.projects[1]} backIsHome={true} />
                                    {/* <AnimationCircle className="ani-circle">
                                        <div className="current"></div>
                                        <div></div>
                                        <div></div>
                                    </AnimationCircle> */}
                                </React.Fragment>
                            )}
                        </AnimationBox>
                    </CardBox>
                    <BtnCenterBox>
                        <Link to="/research/projects?page=1">
                            <HomeBtn>PROJECTS</HomeBtn>
                        </Link>
                    </BtnCenterBox>
                </div>
            </Section>
            <Section>
                <FlexBox>
                    <TextBox className="padding">
                        <h1>Publications</h1>
                        {datas.intros && <p>{datas.intros[3]["Contents"]}</p>}
                        <BtnBox>
                            <Link to="/publications/publications?page=1">
                                <HomeBtn>PUBLICATIONS</HomeBtn>
                            </Link>
                        </BtnBox>
                    </TextBox>
                    <PublicationBox>
                        <AnimationBox initDirection="column">
                            {datas.publications && (
                                <React.Fragment>
                                    <PublicationCard clone="clone" isHome={true} item={datas.publications[1]} />
                                    <PublicationCard clone="clone" isHome={true} item={datas.publications[2]} />
                                    {datas.publications.map((item) => (
                                        <PublicationCard
                                            isHome={true}
                                            item={item}
                                            key={item._id}
                                        />
                                    ))}
                                    <PublicationCard clone="clone" isHome={true} item={datas.publications[0]} />
                                    <PublicationCard clone="clone" isHome={true} item={datas.publications[1]} />
                                    {/* <AnimationCircle className="ani-circle">
                                        <div className="current"></div>
                                        <div></div>
                                        <div></div>
                                    </AnimationCircle> */}
                                </React.Fragment>
                            )}
                        </AnimationBox>
                        <BtnCenterBox>
                            <Link to="/publications/publications?page=1">
                                <HomeBtn>PUBLICATIONS</HomeBtn>
                            </Link>
                        </BtnCenterBox>
                    </PublicationBox>
                </FlexBox>
            </Section>
            {/* <Section>
                <div>
                    <SectionTitle>
                        <h1>Seminar</h1>
                    </SectionTitle>
                    <CardBox height="400">
                        <AnimationBox ref={elem => (animationBoxRef.current[3] = elem)}>
                            {datas.seminars && (
                                <React.Fragment>
                                    <div>
                                        {datas.seminars.map((item) => (
                                                <SeminarCard item={item} key={item._id} backIsHome={true} />
                                            ))}
                                    </div>
                                    <AnimationCircle className="ani-circle">
                                        <div className="current"></div>
                                        <div></div>
                                        <div></div>
                                    </AnimationCircle>
                                </React.Fragment>
                            )}
                        </AnimationBox>
                    </CardBox>
                    <BtnCenterBox>
                        <Link to="/research/seminar?page=1">
                            <HomeBtn>SEMINAR</HomeBtn>
                        </Link>
                    </BtnCenterBox>
                </div>
            </Section> */}
            <Section>
                <div>
                    <SectionTitle>
                        <h1>Seminar</h1>
                        {/* {datas.intros && <p>{datas.intros[2]["Contents"]}</p>} */}
                    </SectionTitle>
                    <CardBox>
                        <AnimationBox initDirection="row">
                            {datas.seminars && (
                                <React.Fragment>
                                    <ProjectCard clone="clone" item={datas.seminars[1]} backIsHome={true} />
                                    <ProjectCard clone="clone" item={datas.seminars[2]} backIsHome={true} />
                                    {datas.seminars.map((item) => (
                                            <SeminarCard item={item} key={item._id} backIsHome={true} />
                                    ))}
                                    <ProjectCard clone="clone" item={datas.seminars[0]} backIsHome={true} />
                                    <ProjectCard clone="clone" item={datas.seminars[1]} backIsHome={true} />
                                    {/* <AnimationCircle className="ani-circle">
                                        <div className="current"></div>
                                        <div></div>
                                        <div></div>
                                    </AnimationCircle> */}
                                </React.Fragment>
                            )}
                        </AnimationBox>
                    </CardBox>
                    <BtnCenterBox>
                        <Link to="/research/seminar?page=1">
                            <HomeBtn>SEMINAR</HomeBtn>
                        </Link>
                    </BtnCenterBox>
                </div>
            </Section>
        </Container>
    </div>
);
