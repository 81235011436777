/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../../Components/Page";
import ProjectPost from "../../../../Components/ProjectPost";

export default ({ project, goBack, backIsHome, history }) => (
    <Page>
        <div className="inner">
            {project &&
                <ProjectPost
                    data={project}
                    goBack={goBack}
                    history={history}
                    backIsHome={backIsHome}
                />}
        </div>
    </Page>
);
