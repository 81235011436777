import React, { useState, useEffect, useRef } from "react";
import Presenter from "./professor.presenter";
import { isoftlab } from "../../../api";

export default () => {
    const useProfessor = () => {
        const [professor, setProfessor] = useState(null);
        const [info, setInfo] = useState(null);
        const overYears = useRef([]);

        const init = async () => {
            const { data } = await isoftlab.getProfessor();

            setInfo(data.Information[0]);
            setProfessor(data);
        };

        const clickSeemore = (event) => {
            const { target } = event;

            target.classList.toggle("seemore");
            if (overYears.current) {
                overYears.current.forEach((overYear) => {
                    overYear.classList.toggle("hide");
                });
            }

            if (target.classList.contains("seemore")) {
                target.innerText = "See more";
            } else {
                target.innerText = "Close";
            }
        };

        useEffect(() => {
            init();
        }, []);

        return {
            professor,
            overYears,
            clickSeemore,
            info,
        };
    };

    return <Presenter {...useProfessor()} />;
};
