import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ImgBox = styled.div`
    width: 100%;
    height: 306px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 100%;
        display: block;
    }
`;

const ItemContent = styled.div`
    padding: 9px 15px;

    & > h4 {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 14px;
        font-size: 17px;
        color: #000000;
    }

    & > ul {
        & > li {
            font-size: 14px;
            color: #000000;
            list-style-type: none;
        }
    }
`;

const Item = styled.div`
    overflow: hidden;
    border: 1px solid #769af2;
    cursor: pointer;
    position: relative;

    & > .detail {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 14px;
        text-decoration: underline;
        color: #fff;
        display: none;
        z-index: 10;
    }

    & .itemContent {
        background-color: #ffffff;
    }

    &:hover {
        ${ItemContent} {
            background-color: rgba(80, 124, 242, 0.7);
            
            * {
                color: #ffffff;
            }
        }

        & > .imgBox {
            & > img,
            & > .tempImg {
                opacity: 0.3;
            }
            background-color: rgba(80, 124, 242);
        }

        & > .detail {
            display: block;
        }

        /* & h4 {
            font-family: "Apple SD Gothic Neo", sans-serif;
        } */
    }
`;

export default ({item, page, category, keyword, clickCard, backIsHome}) => {
    return (
        <Link to={{
            pathname: `/resource/contents/${item._id}`,
            state: {
                data: item,
                page,
                category,
                keyword,
                ...(backIsHome && {backIsHome})
            }
        }}
            onClick={clickCard}
        >
            <Item>
                {/* <div className="detail">자세히 보기</div> */}
                <ImgBox className="imgBox">
                    <img src={item.Image ?? ""} />
                </ImgBox>
                <ItemContent className="itemContent">
                    <h4>{item.Title}</h4>
                    <ul>
                        <li>{ item.Date }</li>
                    </ul>
                </ItemContent>
            </Item>
        </Link>
    )
}