import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  & > div {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(47, 47, 47, 0.6);
    color: #ffffff;
    font-size: 24px;
    padding: 0 20px;
    text-align: center;
  }

  &:hover {
    & > div {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
  }
`;

export default function LiveDemoCard({data, category, keyword}) {
  return (
    <Link to={{
      pathname: `/livedemo/contents/${data._id}`,
      state: {
        data,
        category,
        keyword
      }
    }}>
      <Container>
        <img src={data.Image} />
        <div><span>{data.Title}</span></div>
      </Container>
    </Link>
  )
}