import React, { useState } from "react";
import Router from "./Router";
import Footer from "./Footer";
import styled from "styled-components";
import GlobalStyles from "./GlobalStyles";

const ClipBoard = styled.input`
    opacity: 0;
    min-height: 0;
`;

const SuccessBox = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    width: 330px;
    height: 140px;
    background-color: #507cf2;
    border-radius: 15px;
    color: #fff;
    box-shadow: 0 4px 30px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    display: flex;
    opacity: 0;
    z-index: -9999;
    align-items: center;

    @media only screen and (max-width: 500px) {
        width: 270px;
        height: 120px;
        padding: 20px;
    }
`;

const SuccessTop = styled.div`
    position: absolute;
    top: 18px;
    right: 18px;

    & > span {
        font-size: 28px;
        font-weight: 900;
        cursor: pointer;
    }
`;

const SuccessText = styled.div`
    display: flex;
    align-items: center;
    color: #fff;

    & > span:first-child {
        font-size: 68px;
        margin-right: 20px;
    }

    & > span:last-child {
        font-size: 28px;
        font-weight: 600;
    }

    @media only screen and (max-width: 500px) {
        & > span:first-child {
            font-size: 58px;
            margin-right: 20px;
        }

        & > span:last-child {
            font-size: 24px;
            font-weight: 600;
        }
    }
`;

const GoToTop = styled.div`
    div {
        position: fixed;
        right: 20px;
        bottom: 40px;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        color: #769af2;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
        z-index: 10000;

        span {
            font-size: 50px;
            font-weight: 900;
        }

        @media only screen and (min-width: 500px) {
            &:hover {
                background-color: #507cf2;
                color: #fff;
            }
        }

        @media only screen and (max-width: 500px) {
            width: 50px;
            height: 50px;

            span {
                font-size: 30px;
            }
        }
    }
`;

const clickExitSuccess = (event) => {
    const { target } = event;
    const successBox = target.parentNode.parentNode;

    successBox.style.transition = "none";
    successBox.style.opacity = "0";
    successBox.style.zIndex = "-9999";
};

function App() {
    const projectTypeState = useState(false);
    return (
        <div className="App">
            <GlobalStyles />
            <Router projectTypeState={projectTypeState} />
            <Footer />
            <ClipBoard className="clipBoard hide" type="text" onSelect={(event) => {event.preventDefault()}} />
            <SuccessBox className="successBox">
                <SuccessTop>
                    <span
                        onClick={clickExitSuccess}
                        className="material-icons-outlined"
                    >
                        close
                    </span>
                </SuccessTop>
                <SuccessText>
                    <span className="material-icons">check_circle</span>
                    <span>Success!</span>
                </SuccessText>
            </SuccessBox>
            <GoToTop>
                <div onClick={() => window.scrollTo(0, 0)}>
                    <span className="material-icons-outlined">north</span>
                </div>
            </GoToTop>
        </div>
    );
}

export default App;
