/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { withRouter, Link, useHistory } from "react-router-dom";
import menuList from "./Header.menulist";

const Header = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0 0 10px -5px #303030;
    font-size: 16px;

    & > .inner {
        display: flex;
        justify-content: flex-end;
    }

    @media only screen and (max-width: 1060px) {
        /* height: 80px; */
        display: flex;
        flex-direction: column;
        box-shadow: none;

        &.open {
            box-shadow: 0 0 10px -5px #303030;
        }
    }

    li {
        list-style-type: none;
    }
`;

const LogoBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding-bottom: 10px;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #e8e9eb;
    background-color: #fff;

    & > .inner {
        display: flex;
        justify-content: space-between;
    }

    img {
        position: relative;
        top: 5px;
        width: 150px;
    }

    @media only screen and (max-width: 1060px) {
        position: static;
        height: 80px;
        padding: 0;
        /* border: 0; */
        width: 100%;
        padding-bottom: 10px;
    }

    @media only screen and (max-width: 500px) {
        img {
            top: 0;
            width: 130px;
        }
    }
`;

const GNB = styled.ul`
    position: relative;
    z-index: 10;
    display: flex;
    color: #595f6f;

    & > li {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > * {
            white-space: nowrap;
        }
    }

    & > li:not(:last-child) {
        margin-right: 40px;
    }

    &:hover .subGnb {
        max-height: initial;
    }
    & > li:hover .topMenuLine {
        border-bottom: 3px solid #2b62f5;
    }
    & > li:hover .topMenu {
        color: #2b62f5;
    }

    @media only screen and (max-width: 1060px) {
        display: none;
    }
`;

const TopMenu = styled.div`
    height: 70px;
    display: flex;
    align-items: flex-end;
    position: relative;
`;

const TopMenuLine = styled.div`
    position: absolute;
    bottom: -10px;
    width: 100%;
    border-bottom: ${({ current }) => (current ? "3px solid #2b62f5" : "none")};
`;

const SubGNB = styled.div`
    font-size: 14px;
    & > ul {
        padding: 15px 0;
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > li {
            padding: 5px 0;
        }
    }

    & a:hover {
        color: #2b62f5;
    }

    max-height: 0;
    overflow: hidden;

    @media only screen and (max-width: 1060px) {
        transition: max-height 0.3s ease-in-out;
    }
`;

const MoblieMenuBtn = styled.div`
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: #000;
    transition: color 0.2s ease-in-out;
    display: none;
    & > span {
        font-size: 36px;
    }

    @media only screen and (max-width: 1060px) {
        display: block;
    }
`;

const MobileGnbBox = styled.div`
    display: none;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    @media only screen and (max-width: 1060px) {
        display: block;
    }
    transition: max-height 0.3s ease-in-out;
    background-color: #f2f1f1;
`;

const MobileGNB = styled.ul`
    /* padding: 10px 0; */

    & > li:not(:last-child) {
        border-bottom: 1px solid #d0d0d0;
    }
`;

const MobileTopMenu = styled.div`
    & > a {
        display: block;
        width: 100%;
        padding: 10px 20px;
        font-weight: 500;
    }
`;

const MobileSubGNB = styled.div`
    max-height: 0px;
    overflow: hidden;
    font-size: 15px;
    transition: max-height 0.3s ease-in-out;
    background-color: #fff;

    & ul {
        padding: 5px 0;
    }

    & a {
        display: block;
        width: 100%;
        padding: 8px 30px;
    }

    & a:hover {
        color: #2b62f5;
    }
`;

export default withRouter(
    ({
        history: {
            location: { pathname },
        },
    }) => {
        const history = useHistory();
        const useHeader = () => {
            const header = useRef();
            const links = useRef([]);
            const subGnbs = useRef([]);
            const mobileGnb = useRef();
            const mobileSubGnbs = useRef([]);

            const handleLink = (event) => {
                if (subGnbs.current[0]) {
                    subGnbs.current.forEach((subGnb) => {
                        if (subGnb) {
                            subGnb.style.maxHeight = "0px";
                            setTimeout(() => {
                                subGnb.style.maxHeight = null;
                            }, 1);
                        }
                    });
                }
            };

            const setGnbHeight = (add = 0) => {
                if (mobileGnb.current) {
                    const navList = mobileGnb.current.children[0];
                    mobileGnb.current.style.maxHeight = `${
                        navList.clientHeight + add
                    }px`;
                }
            };

            const subGnbAllClose = () => {
                if (mobileSubGnbs.current[0]) {
                    mobileSubGnbs.current.forEach((subGnb) => {
                        if (subGnb) {
                            subGnb.style.maxHeight = "0px";
                        }
                    });
                }
            };

            const gnbAllClose = () => {
                subGnbAllClose();
                if (mobileGnb.current) {
                    mobileGnb.current.style.maxHeight = `0px`;
                }
            };

            const clickMobileBtn = () => {
                if (header.current) {
                    if (!header.current.classList.contains("open")) {
                        header.current.classList.add("open");
                        setGnbHeight();
                    } else {
                        header.current.classList.remove("open");
                        gnbAllClose();
                    }
                }
            };

            const clickMobileLink = (event, path) => {
                const { target: link } = event;

                if (link.className === "topMenu") {
                    event.preventDefault();
                    const subGnb =
                        link.parentNode.parentNode.querySelector(".subGnb");
                    const subGnbHeight = subGnb.children[0].clientHeight;
                    if (
                        subGnb.style.maxHeight === "" ||
                        subGnb.style.maxHeight === "0px"
                    ) {
                        subGnbAllClose();
                        subGnb.style.maxHeight = `${subGnbHeight}px`;
                        setGnbHeight(subGnbHeight);
                    } else {
                        subGnb.style.maxHeight = `0px`;
                        setGnbHeight();
                    }
                } else {
                    if (path === pathname) {
                        window.location.reload();
                    } else {
                        header.current.classList.remove("open");
                        gnbAllClose();
                    }
                }
            };

            const clickLink = (path) => {
                if (pathname === path) {
                    setTimeout(() => {
                        window.location.reload();
                    })
                }
            };

            useEffect(() => {
                if (links.current) {
                    links.current.forEach((link) => {
                        if (link) {
                            link.addEventListener("click", handleLink);
                        }
                    });
                }

                return () => {
                    if (links.current) {
                        links.current.forEach((link) => {
                            if (link) {
                                link.removeEventListener("click", handleLink);
                            }
                        });
                    }
                };
            }, []);

            return {
                header,
                links,
                subGnbs,
                mobileGnb,
                mobileSubGnbs,
                clickMobileBtn,
                clickMobileLink,
                clickLink,
            };
        };

        const {
            header,
            links,
            subGnbs,
            mobileGnb,
            mobileSubGnbs,
            clickMobileBtn,
            clickMobileLink,
            clickLink,
        } = useHeader();

        return (
            <Header ref={header}>
                <LogoBox>
                    <div className="inner">
                        <Link to="/" ref={(el) => links.current.push(el)}>
                            <img src="/img/logo-vertical.png" />
                        </Link>
                        <MoblieMenuBtn
                            className="mobileMenuBtn"
                            onClick={clickMobileBtn}
                        >
                            <span className="material-icons-outlined">
                                menu
                            </span>
                        </MoblieMenuBtn>
                    </div>
                </LogoBox>
                <div className="inner">
                    <GNB>
                        {menuList.map((menu, menuIdx) => {
                            const { topMenu, topMenuLink, subMenuList, root } = menu;
                            return (
                                <li key={menuIdx}>
                                    <TopMenu className="topMenu">
                                        <Link
                                            to={topMenuLink}
                                            className={pathname === topMenuLink.split("?")[0] ? "currentPath" : ""}
                                            ref={(el) => links.current.push(el)}
                                            onClick={() => {
                                                clickLink(topMenuLink);
                                            }}
                                        >
                                            {topMenu}
                                        </Link>
                                        <TopMenuLine
                                            // current={
                                            //     pathname.includes(
                                            //         topMenuLink
                                            //     ) ||
                                            //     (subMenuList &&
                                            //         subMenuList.reduce(
                                            //             (a, b) => {
                                            //                 if (!a)
                                            //                     return pathname.includes(
                                            //                         b.subMenuLink
                                            //                     );
                                            //                 return true;
                                            //             },
                                            //             false
                                            //         ))
                                            // }
                                            current={`/${pathname.split("/")[1]}` === root}
                                            className="topMenuLine"
                                        ></TopMenuLine>
                                    </TopMenu>
                                    {subMenuList && (
                                        <SubGNB
                                            ref={(el) =>
                                                subGnbs.current.push(el)
                                            }
                                            className="subGnb"
                                        >
                                            <ul>
                                                {subMenuList.map(
                                                    (sub, subMenuIdx) => {
                                                        const {
                                                            subMenu,
                                                            subMenuLink,
                                                        } = sub;

                                                        return (
                                                            <li
                                                                key={subMenuIdx}
                                                            >
                                                                <Link
                                                                    to={
                                                                        subMenuLink
                                                                    }
                                                                    ref={(el) =>
                                                                        links.current.push(
                                                                            el
                                                                        )
                                                                    }
                                                                    className={pathname === subMenuLink.split("?")[0] ? "currentPath" : ""}
                                                                    onClick={() => {
                                                                        clickLink(
                                                                            subMenuLink
                                                                        );
                                                                    }}
                                                                >
                                                                    {subMenu}
                                                                </Link>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </SubGNB>
                                    )}
                                </li>
                            );
                        })}
                    </GNB>
                </div>
                <MobileGnbBox ref={mobileGnb}>
                    <MobileGNB>
                        {menuList.map((menu, menuIdx) => {
                            const { topMenu, topMenuLink, subMenuList } = menu;
                            return (
                                <li key={menuIdx}>
                                    <MobileTopMenu>
                                        <Link
                                            className={
                                                subMenuList ? "topMenu" : ""
                                            }
                                            to={topMenuLink}
                                            onClick={(event) => {
                                                clickMobileLink(
                                                    event,
                                                    topMenuLink
                                                );
                                            }}
                                        >
                                            {topMenu}
                                        </Link>
                                    </MobileTopMenu>
                                    {subMenuList && (
                                        <MobileSubGNB
                                            className="subGnb"
                                            ref={(el) =>
                                                mobileSubGnbs.current.push(el)
                                            }
                                        >
                                            <ul>
                                                {subMenuList.map(
                                                    (sub, subMenuIdx) => {
                                                        const {
                                                            subMenu,
                                                            subMenuLink,
                                                        } = sub;

                                                        return (
                                                            <li
                                                                key={subMenuIdx}
                                                            >
                                                                <Link
                                                                    to={
                                                                        subMenuLink
                                                                    }
                                                                    onClick={(
                                                                        event
                                                                    ) => {
                                                                        clickMobileLink(
                                                                            event,
                                                                            subMenuLink
                                                                        );
                                                                    }}
                                                                >
                                                                    -&nbsp;
                                                                    {subMenu}
                                                                </Link>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </MobileSubGNB>
                                    )}
                                </li>
                            );
                        })}
                    </MobileGNB>
                </MobileGnbBox>
            </Header>
        );
    }
);
