/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../../Components/Page";
import SeminarPost from "../../../../Components/SeminarPost";

export default ({ seminar, goBack, history, backIsHome }) => (
    <Page>
        <div className="inner">
            {seminar &&
                <SeminarPost
                    data={seminar}
                    goBack={goBack}
                    history={history}
                    backIsHome={backIsHome}
                />}
        </div>
    </Page>
);
