/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Page from "../../../Components/Page";
import PageController from "../../../Components/PageController";
import TotalBox from "../../../Components/TotalBox";
import SearchBox from "../../../Components/SearchBox";
import styled from "styled-components";
import { Link } from "react-router-dom";

const AlbumContainer = styled.div`
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-auto-rows: 370px;
    gap: 65px;


    @media only screen and (max-width: 1000px) {
        /* grid-template-columns: repeat(2, 1fr); */
        gap: 40px;
    }

    /* @media only screen and (max-width: 750px) {
        gap: 20px;
    }

    @media only screen and (max-width: 600px) {
        grid-auto-rows: minmax(300px, auto);
        grid-template-columns: repeat(1, 1fr);
    } */
`;

const PhotoBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    overflow: hidden;

    &:hover {
        background-color: rgb(43, 98, 245);
        & > div {
            background: none;

            & > div:first-child {
                display: block;
                overflow: initial;
                text-overflow: initial;
                word-wrap: initial;
                display: initial;
                -webkit-line-clamp: initial;
                -webkit-box-orient: initial;
                font-size: 19px;
                font-weight: 500;
                margin-bottom: 10px;
            }
        }

        & > img {
            opacity: 0.3;
        }

        & > span {
            display: block;
        }
    }

    & > span {
        position: absolute;
        top: 15px;
        right: 15px;
        text-decoration: underline;
        font-size: 14px;
        color: #ffffff;
        display: none;
    }
`;

const PhotoText = styled.div`
    position: absolute;
    height: 60%;
    z-index: 100;
    padding: 20px;
    padding-top: 0px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(to top, #000000, #00000000);
    width: 100%;

    & > div:first-child {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    & > div:last-child {
        font-size: 14px;
        font-weight: 200;
    }

    @media only screen and (max-width: 750px) {
        & > div:first-child {
            font-size: 15px;
        }

        & > div:last-child {
            font-size: 13px;
        }
    }
`;

const PhotoImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

export default ({
    filteredData,
    viewData,
    currentPage,
    pageList,
    keyword,
    searchInput,
    clickPageNum,
    clickNextBtn,
    clickPrevBtn,
    clickLastBtn,
    clickFirstBtn,
    clickCard,
    handleSearch,
}) => (
    <Page title="Album">
        <div className="inner">
            <SearchBox
                handleSearch={handleSearch}
                searchInput={searchInput}
                initKeyword={keyword}
            />
            {filteredData && (
                <TotalBox
                    total={filteredData.length}
                    isFilltered={keyword && keyword !== ""}
                />
            )}
            <AlbumContainer>
                {viewData &&
                    viewData.map(
                        (photo, idx) =>
                            photo.Title && (
                                <Link
                                    key={photo._id}
                                    to={{
                                        pathname: `/board/album/contents/${photo._id}`,
                                        state: {
                                            data: photo,
                                            page: currentPage,
                                            keyword,
                                            backIsHome: false
                                        },
                                    }}
                                    onClick={clickCard}
                                >
                                    <PhotoBox>
                                        <PhotoText>
                                            <div>
                                                <span>{photo.Title}</span>
                                            </div>
                                            <div>{photo.Date}</div>
                                        </PhotoText>
                                        {photo.Image &&
                                            photo.Image.length > 0 && (
                                                <PhotoImg
                                                    src={photo.Image}
                                                    alt="그림 이미지"
                                                />
                                            )}
                                        <span>자세히 보기</span>
                                    </PhotoBox>
                                </Link>
                            )
                    )}
            </AlbumContainer>
            <PageController
                clickFirstBtn={clickFirstBtn}
                clickLastBtn={clickLastBtn}
                clickNextBtn={clickNextBtn}
                clickPageNum={clickPageNum}
                clickPrevBtn={clickPrevBtn}
                filteredData={filteredData}
                currentPage={currentPage}
                pageList={pageList}
            />
        </div>
    </Page>
);
