/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { usePaging, getUrl, paramsParsing } from "../../../utils";
import Presenter from "./projects.presenter";
import { withRouter } from "react-router-dom";
import { isoftlab } from "../../../api";

const MAX_VIEW_PAGE = 5;
const MAX_VIEW_DATA = 12;
const searchOption = {
    search: ["Title", "Date", "Institution", "Keyword", "Participatents"],
    category: "Category"
}

export default withRouter((props) => {
    const {projectTypeState} = props;
    const [listView, setListView] = projectTypeState;

    return (
        <Presenter
            {...usePaging({
                dataFn: isoftlab.getProject,
                maxViewPage: MAX_VIEW_PAGE,
                maxViewData: MAX_VIEW_DATA,
                searchOption,
                urlProps: props
            })}
            listView={listView}
            setListView={setListView}
        />
    )
});