import React, { useEffect, useState } from "react";
import Presenter from "./seminar-detail.presenter";
import { withRouter } from "react-router-dom";
import { isoftlab } from "../../../../api";

export default withRouter(
    ({
        history,
        history: {goBack},
        location: { state }
    }) => {
        const useSeminar = () => {
            const [seminar, setSeminar] = useState(null);

            const init = async () => {
                if (!state || !state.data) {
                    let id = history.location.pathname.split("/").pop();
                    const {data} = await isoftlab.getSeminar();
                    const detailData = data.filter(d => d["_id"] === id);

                    if (detailData.length > 0) {
                        setSeminar(detailData[0]);
                    } else {
                        history.push("/error");
                    }
                } else {
                    setSeminar(state.data);
                    window.scrollTo(0, 0);
                }
            }

            useEffect(() => {
                init();
            }, []);

            return { seminar };
        };

        const { seminar } = useSeminar();
        
        return <Presenter seminar={seminar} goBack={goBack} history={history} backIsHome={state ? state.backIsHome : null} />;
    }
);
